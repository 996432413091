export const dayWeekList = [
  {id: 0, name: 'Monday'},
  {id: 1, name: 'Tuesday'},
  {id: 2, name: 'Wednesday'},
  {id: 3, name: 'Thursday'},
  {id: 4, name: 'Friday'},
  {id: 5, name: 'Saturday'},
  {id: 6, name: 'Sunday'},
];

export const defaultDayWeekRows = [{
  id: 0,
  startDayId: null,
  endDayId: null,
  allocation: 0,
  exclude: false,
},];

export const defaultDayPartRows = [{
  id: 0,
  daypartId: null,
  allocation: 0,
  exclude: false,
}];



// DropdownList Type
export const ExcludeFieldType = {
  "RATINGS": 0,
  "UNIT_PRICE": 1,
}

export const DropdownListType = {
  "TOP_RATED": 0,
  "GREATER_THAN": 1,
  "LESS_THAN": 2,
  "BOTTOM_RATED": 3,
}

// Exclude Avails Dropdownlist Options
export const ExcludesDropdownLists = {
  exclude1: [
    { id: DropdownListType.GREATER_THAN, name: 'Ratings greater than' },
    { id: DropdownListType.TOP_RATED, name: 'Top rated(%)' },
  ],
  exclude2: [
    { id: DropdownListType.LESS_THAN, name: 'Ratings less than' },
    { id: DropdownListType.BOTTOM_RATED, name: 'Bottom rated(%)' },
  ],
  exclude3: [
    { id: DropdownListType.GREATER_THAN, name: 'Unit price greater than' },
    { id: DropdownListType.TOP_RATED, name: 'Top rated(%) unit price' },
  ],
  exclude4: [
    { id: DropdownListType.LESS_THAN, name: 'Unit price less than' },
    { id: DropdownListType.BOTTOM_RATED, name: 'Bottom rated (%) unit price' },
  ],
};

export const defaultAvailsExcludes = [
  ExcludesDropdownLists.exclude1[0],
  ExcludesDropdownLists.exclude2[0],
  ExcludesDropdownLists.exclude3[0],
  ExcludesDropdownLists.exclude4[0],
];
