import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { filterParams } from "components/campaigns/helpers/filter";
import { formatAmount, orderStatus } from "components/campaigns/helpers/helpers";
import useStore from "components/campaigns/hooks/useCampaignStore";
import { getAllMarketStations } from "components/campaigns/requests";
import { OpDataGrid } from "op2mise-react-widgets";
import Button from "shared/components/button/Button";
import "../../campaigns.css";

const NotifyAllMarketsModal = ({ onHide, onSelectStations, selectedCampaign, setShowComposeEmailForm }) => {
    const { dateFormat } = useStore((state) => state);
    const [stations, setStations] = React.useState();
    const [selectedStations, setSelectedStations] = React.useState([]);

    const handleGetStations = async () => {
        const res = await getAllMarketStations(selectedCampaign.id);
        setStations(res.stations);
    };

    const onSelectionChanged = (selectedNodes) => {
        onSelectStations(selectedNodes.map((o) => o.data.id));
        setSelectedStations(selectedNodes);
    };

    // Op data grid settings
    const headers = ['marketName', 'stationName', 'networkName', 'salesPerson', 'createdDate', 'filtered', 'programCount', 'orderStatus', 'lastNotified'];

    const displayDate = (params) => params.value ? moment(params.value).format(dateFormat) : null;
    const displayLastSeen = (params) => params.data?.hasContacts
        ? params.value
            ? moment(params.value).format(dateFormat)
            : null
        : params.node.group ? null : 'No active station contact found'
    const displayEdited = (params) => params.value ? 'Yes' : 'No';
    const displayOrderStatus = (params) => orderStatus[params.value];
    
    const columnDefs = React.useMemo(() => [
        { headerName: 'STATION/CABLE', field: 'marketName', rowGroup: true, hide: true },
        { headerName: 'STATION/CABLE', field: 'stationName' },
        { headerName: 'NETWORK', field: 'networkName' },
        { headerName: 'SALES PERSON', field: 'salesPerson' },
        {
            headerName: 'DATE RECEIVED',
            field: 'createdDate',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            valueFormatter: displayDate,
            tooltipValueGetter: displayDate,
        },
        {
            cellDataType: 'text',
            headerName: 'EDITED',
            field: 'filtered',
            filterParams: {
                valueFormatter: displayEdited,
            },
            valueFormatter: displayEdited,
            tooltipValueGetter: displayEdited,
        },
        {
            headerName: 'PROGRAM COUNT',
            field: 'programCount',
            filter: 'agNumberColumnFilter',
            valueFormatter:(params) => formatAmount(params.value),
            tooltipValueGetter:(params) => formatAmount(params.value),
        },
        {
            headerName: 'ORDER STATUS',
            field: 'orderStatus',
            filterParams: {
                valueFormatter: displayOrderStatus,
            },
            valueFormatter: displayOrderStatus,
            tooltipValueGetter: displayOrderStatus,
        },
        {
            headerName: 'LAST NOTIFIED',
            field: 'lastNotified',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            valueFormatter: displayLastSeen,
            tooltipValueGetter: displayLastSeen,
        },
    ], []);

    const autoGroupColumnDef = React.useMemo(() => {
        return {
            cellRendererParams: { suppressCount: true },
            field: 'stationName',
            headerName: 'STATION/CABLE',
            sortable: true,
            filter: true,
            sort: "asc",
            width: 280,
            minWidth: 130,
            tooltipValueGetter: (params) => {
                const { node, value } = params;
                const masterRow = node.level === 0;

                if (masterRow) {
                    // remove total value for stations from recieved data
                    const newMarketName = value.replace(/\s+\|\s+\d+$/, '');
                    // get selected rows in group
                    const selectedChildCount = node.allLeafChildren.filter((child) => child.isSelected()).length;
                    return  `${newMarketName} | ${selectedChildCount}/${node.allChildrenCount}`;
                }

                // return unformatted value if not master row
                return value;
            },
        }
    }, []);

    const rowDataBuilder = (data) => {
        const rows = data;
        const rowData = []; // to be returned;

        rows?.forEach((station) => {
            rowData.push(station);
        });
        return rowData;
    };

    const DataGridRowData = React.useMemo(() => {
        return rowDataBuilder(stations);
    }, [stations]);

    const rowSelection = React.useMemo(() => {
        return {
            mode: 'multiRow',
            checkboxes: true,
            headerCheckboxes: true,
            headerClass: 'hide-header-selection-label',
            isRowSelectable: (params) => {
                // status: New, Pending Review, Confirmed, Cancellation Request Sent
                const statuses = [0, 2, 3, 5];
                const noContacts = !params.data?.hasContacts;
                const isWideOrbit = params.data?.orderPlatform === 'Wide Orbit';
                const disabled = !((statuses.includes(params.data?.orderStatus) && isWideOrbit) || noContacts);

                return disabled;
            },
            groupSelects: 'descendants', 
        };
    }, []);

    React.useEffect(() => {
        handleGetStations();
    }, [])
    
    return (
        <Modal centered dialogClassName={'market-stations-modal'} onHide={onHide} show={true}>
            <div className={'station-wrapper'}>
                <p>Select station(s) to notify</p>
                {stations && (
                    <OpDataGrid
                        autoGroupColumnDef={autoGroupColumnDef}
                        columns={columnDefs}
                        customFooterElement={() => (
                            <span style={{ color: '#656565', fontSize: '12px' }}>
                                {selectedStations.length} Station(s) selected
                            </span>
                        )}
                        customVerbiage={'No Stations/Cables Found'}
                        defaultSort={{ field: 'stationName', sort: 'asc' }}
                        gridHeightBuffer={500}
                        gridName={'mp-campaign-form-notification-selected-market-modal'}
                        groupDefaultExpanded={0}
                        onGridReady={(params) => {
                            const nodesToSelect = [];
                            
                            params.api.forEachNode((node) => {
                                if (node.selectable && !node.group) nodesToSelect.push(node);
                            });
                            
                            params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
                        }}
                        onSelectionChanged={onSelectionChanged}
                        resizable={true}
                        rowId={'stationId'}
                        rows={DataGridRowData}
                        rowSelection={rowSelection}
                        sharedColumnProperties={{
                            cellStyle: { fields: ['programCount'], value: { textAlign: 'right' } },
                            filter: {
                                fields: headers.filter(name => !['createdDate', 'lastNotified', 'programCount'].includes(name)),
                                value: true,
                            },
                            flex: { fields: headers, value: 1 },
                            sortable: { fields: headers, value: true },
                        }}
                        showAlternativeRowHighlight={true}
                        suppressRowClickSelection={true}
                    />
                )}

                <div className={'d-flex justify-content-end mt-5'}>
                    <Button
                        defaultBtn={true}
                        onClick={onHide}
                        text={'Cancel'}
                    />
                    <Button
                        disabled={!selectedStations.length}
                        onClick={setShowComposeEmailForm}
                        text={'Next'}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default NotifyAllMarketsModal;