/* eslint-disable import/prefer-default-export */
import request from '../../requests';

export const getAvailsStation = async (id, marketId, parameters) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const avails = await request({
      endpoint: `Campaign/${id}/station-avails/${marketId}${parameters}`,
      method: 'GET',
    });
    return avails;
  } catch (error) {
    throw error;
  }
};

export const getCheckAvailsStation = async (id, stationId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const avails = await request({
      endpoint: `Campaign/${id}/station-avails/status/${stationId}`,
      method: 'GET',
    });
    return avails;
  } catch (error) {
    throw error;
  }
};

export const getCheckAvailsStationOverlappingDates = async (
  campaignId,
  stationId,
  formData
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const avails = await request({
      endpoint: `Campaign/${campaignId}/station-avails/check-overlapping-dates/${stationId}`,
      method: 'POST',
      headers: {},
      data: formData,
    });
    return avails;
  } catch (error) {
    throw error;
  }
};

export const postAvailsStation = async (id, formData) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const avails = await request({
      endpoint: `Campaign/${id}/station-avails/import`,
      method: 'POST',
      headers: {},
      data: formData,
    });
    return avails;
  } catch (error) {
    throw error;
  }
};

export const getCheckCampaignStatus = async (id) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const avails = await request({
      endpoint: `Campaign/${id}/status`,
      method: 'GET',
    });
    return avails;
  } catch (error) {
    throw error;
  }
};

export const saveAllocations = async (formData) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const avails = await request({
      endpoint: `Avails/station/allocations/save`,
      method: 'POST',
      headers: {},
      data: formData,
    });
    return avails;
  } catch (error) {
    throw error;
  }
};

export const getOrderStations = async (campaignId, marketId) => {
  try {
    const stations = await request({
      endpoint: `Order/campaign/${campaignId}/market/${marketId}/stations`,
      method: 'GET',
    });
    return stations;
  } catch (error) {
    throw error;
  }
};

export const sendOrderToStations = async (stationOrders) => {
  try {
    const stationOrderRes = await request({
      endpoint: 'Order',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(stationOrders),
    });
    return stationOrderRes;
  } catch (error) {
    throw error;
  }
};
