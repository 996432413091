import React, { useCallback, useEffect, useState } from 'react';
import useStore from '../hooks/useCampaignStore';
import Input from 'shared/components/input/Input';
import Select from 'shared/components/select/Select';
import { formatDecimal } from '../helpers/helpers';
import { ExcludesDropdownLists } from '../CampaignConstants';

function ExcludeAvailsFields({availsExcludes, setAvailsExcludes, setIsFormTouched}) {
  const { isCampaignActive } = useStore((state) => state);

  // Helpers
  const cleanValue = (value) => {
    return value ? (value.includes('.') ? value : parseFloat(value)) : null;
  };

  // Input Handling
  const handleExcludeSelection = useCallback((id, data) => {
    setIsFormTouched(true);

    setAvailsExcludes(prev => prev.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          value: "", // Reset value when dropdown changes
          selectedExclude: data.id,
        };
      }
      return row;
    }))

    
  }, [availsExcludes, setIsFormTouched]);

  const handleOnExcludeInputChange = (id, e, selectedExclude) => {
    setIsFormTouched(true);
   
    let val = e.target.value;
    
    if(selectedExclude.id === 2) {
      setAvailsExcludes(prev => prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            value: val,
          };
        }
        return row;
      }))

      return;
    }

    let cleanedValue = cleanValue(e.target.value);

    if (String(cleanedValue).length > 9) return false;

    setAvailsExcludes(prev => prev.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          value: cleanedValue,
        };
      }
      return row;
    }))
    
  };

  return (
    <>
      <div className="d-flex flex-column gap-10">
        {/* Section Title */}
        <div className="w-100 border-bottom d-flex">
          <span>Exclude avails</span>
        </div>
      </div>

      <div className="w-100 d-flex flex-column gap-2">
        <div className="rows-container">
          </div>
            {availsExcludes.map((exclude, index) => {

              const CurrentExcludeDropdownList = Object.values(ExcludesDropdownLists)[index];

              // Check if the selected exclude is the first index
              const isFirstIndex = CurrentExcludeDropdownList.findIndex((item) => item.id === exclude.selectedExclude) === 0;
              
              return (
                <div className="excludes-rows">
                  <div style={{ width: '100%', maxWidth: '210px' }}>
                    <Select
                      text={
                        CurrentExcludeDropdownList.find(item => item.id === exclude.selectedExclude)?.name || CurrentExcludeDropdownList[0]?.name
                      }
                      list={CurrentExcludeDropdownList}
                      noSearch
                      onSelect={(e) => handleExcludeSelection(exclude.id, e)}
                      width={'100%'}
                      disabled={!isCampaignActive}
                    />
                  </div>
                  <Input
                    value={isFirstIndex ? formatDecimal(exclude.value) : exclude.value}
                    maxLength={isFirstIndex ? 11 : 5}
                    type={isFirstIndex ? 'number' : 'decimal'}
                    onChange={(e) => handleOnExcludeInputChange(exclude.id, e, exclude.selectedExclude)}
                    style={{ width: '100%', maxWidth: '85px',color: '#212529'}}
                    disabled={!isCampaignActive}
                  />
                </div>
              );
            })}
      </div>
    </>
  );
}

export default ExcludeAvailsFields;
