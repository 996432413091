import React from "react";
import { Modal } from "react-bootstrap";
import { sendTestEmail } from "components/campaigns/requests";
import StringService from "core/string.service";
import Button from "shared/components/button/Button";
import Input from "shared/components/input/Input";

const SendTestEmailForm = ({ onHide, selectedCampaign, selectedEmailTemplate }) => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isSendingTestEmail, setIsSendingTestEmail] = React.useState(false);
    const [recipients, setRecipients] = React.useState('');

    const validateForm = () => {
        let isValid = true;

        recipients.split(';').forEach(email => {
            if (!StringService.isValidEmail(email)) isValid = false;
        });

        return isValid;
    };

    const handleSendTestEmail = async () => {
        setErrorMessage('');
        if (validateForm()) {
            setIsSendingTestEmail(true);
            await sendTestEmail(
                selectedEmailTemplate.id,
                { campaignId: selectedCampaign.id, recipients },
            );
            onHide();
            setIsSendingTestEmail(false);
        } else {
            setErrorMessage('Recipient(s) is not a valid email address');
        }
    };

    return (
        <Modal centered dialogClassName={'send-test-email-form-modal'} onHide={onHide} show={true}>
            <div className={'send-test-email-form-wrapper'}>
                <p className={'send-test-email-form-title'}>Test email notification</p>

                <Input
                    asteriskOn={true}
                    errorMessage={errorMessage}
                    label={'Recipient(s)'}
                    onChange={(event) => setRecipients(event.target.value)}
                    type={'email'}
                    value={recipients}
                />

                <div className={'text-end mt-3'}>
                    <Button defaultBtn onClick={onHide} text={'Cancel'} />
                    <Button isLoading={isSendingTestEmail} onClick={handleSendTestEmail} text={'Send a test email'} />
                </div>
            </div>
        </Modal>
    );
};

export default SendTestEmailForm;