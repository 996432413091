import React from "react";
import { Modal } from "react-bootstrap";
import Button from "shared/components/button/Button";
import Input from "shared/components/input/Input";

const TemplateNameForm = ({ selectedEmailTemplate, onHide, onSave, onUpdate, type }) => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [name, setName] = React.useState('');

    const handleOnSave = () => {
        if (name) {
            setErrorMessage('');
            if (type === 'Create') onSave({ id: null, name });
            else onUpdate({ id: selectedEmailTemplate.id, name });
        } else {
            setErrorMessage('Template name is required');
        }
    }

    React.useEffect(() => {
        if (type === 'Edit') setName(selectedEmailTemplate.name);
        else setName('');
    }, [selectedEmailTemplate, type]);

    return (
        <Modal centered dialogClassName={'template-name-form-modal'} onHide={onHide} show={true}>
            <div className={'template-name-form-wrapper'}>
                <p className={'template-name-form-title'}>New Template</p>

                <Input
                    asteriskOn={true}
                    errorMessage={errorMessage}
                    label={'Template Name'}
                    maxLength={'100'}
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                />

                <div className={'text-end mt-3'}>
                    <Button defaultBtn onClick={onHide} text={'Cancel'} />
                    <Button
                        onClick={handleOnSave}
                        text={type === 'Create' ? 'Save' : 'Update'}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default TemplateNameForm;