import { Modal } from "react-bootstrap";
import Button from "shared/components/button/Button";
import "../../campaigns.css";

const SuccessfullyNotifiedModal = ({ onHide, selectedStations }) => {
    return (
        <Modal  centered dialogClassName={'successfully-notified-modal'} onHide={onHide} show={true}>
            <div className={'successfully-notified-wrapper'}>
                <p className={'successfully-notified-title'}>{selectedStations.length} station contacts were notified.</p>

                <Button defaultBtn onClick={onHide} text={'Close'} />
            </div>
        </Modal>
    );
};

export default SuccessfullyNotifiedModal;