import React, { useCallback } from 'react';
import Input from 'shared/components/input/Input';
import Select from 'shared/components/select/Select';
import Switch from 'shared/components/switch/Switch';
import { plusCircleAltIcon } from "assets";
import { trashcanGreenIcon } from 'shared/assets';
import { allow1Point, allow2Decimals } from '../helpers/helpers';
import useStore from '../hooks/useCampaignStore';

const DaySpendFields = (props) => {
  const {
    dayWeekList,
    dayWeekRows,
    dayPartList,
    dayPartRows,
    dayWeekErrors,
    dayPartErrors,
    formErrors,
    setIsFormTouched,
    setDayWeekRows,
    setDayPartRows,
    setDayWeekErrors,
    setDayPartErrors,
  } = props;

  const { isCampaignActive } = useStore((state) => state);

  // Input Handling
  const handleOnDayWeekSelect = ((dayPosition, selectedRow, data) => {
    const { startDayId: currentStartDayId, endDayId: currentEndDayId } = selectedRow;
    const selectedDayId = data.id;

    setIsFormTouched(true);

    setDayWeekRows((prevRow) => 
      prevRow.map((row) => {
        if (row.id !== selectedRow.id) return row;

        switch (dayPosition) {
          case 'START':
            if (currentEndDayId && selectedDayId > currentEndDayId) {
              return {
                ...row,
                startDayId: selectedDayId,
                endDayId: selectedDayId
              };
            }
            return { ...row, startDayId: selectedDayId, };
          case 'END':
            if (currentStartDayId && selectedDayId < currentStartDayId) {
              return {
                ...row,
                startDayId: selectedDayId,
                endDayId: selectedDayId
              };
            }
            return { ...row, endDayId: selectedDayId };
          default:
            return row;
        }
      })
    );
  });

  const handleOnDayPartSelect = useCallback((selectedId, data) => {
    setIsFormTouched(true);

    setDayPartRows((prevRows) =>
      prevRows.map((row) =>
        row.id === selectedId ? { ...row, daypartId: data.id} : row
      )
    );
  }, [dayPartRows]);

  const handleOnAllocationChange = useCallback((rowName, selectedId, e) => {
    setIsFormTouched(true);

    let val = e.target.value;
    const maxValue = 100;

    if (val.length > 1) val = val.replace(/^0+/, '');

    if (val > maxValue) return;
    val = val.replace('..', '.');

    if (val.includes('.')) {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allow1Point(allow2Decimals(e, val));

    switch (rowName) {
      case 'DAYWEEK':
        setDayWeekRows((prevRows) =>
          prevRows.map((row) =>
            row.id === selectedId ? { ...row, allocation: e.target.value} : row
          )
        );
        break;
      case 'DAYPART':
        setDayPartRows((prevRows) =>
          prevRows.map((row) =>
            row.id === selectedId ? { ...row, allocation: e.target.value} : row
          )
        );
        break;
      default:
        break;
    };
  }, [dayWeekRows, dayPartRows]);

  const handleOnExcludeChange = useCallback((rowName, selectedId, isActive) => {
    setIsFormTouched(true);

    switch (rowName) {
      case 'DAYWEEK':
        setDayWeekRows((prevRows) =>
          prevRows.map((row) =>
            row.id === selectedId ? { ...row, exclude: isActive, allocation: 0} : row
          )
        );
        break;
      case 'DAYPART':
        setDayPartRows((prevRows) =>
          prevRows.map((row) =>
            row.id === selectedId ? { ...row, exclude: isActive, allocation: 0} : row
          )
        );
        break;
      default:
        break;
    }
  }, [setDayWeekRows, setDayPartRows]);

  // Row Adding and Removing Functions
  const handleOnAddRows = useCallback((rowName) => {
    if (!isCampaignActive) return;

    switch (rowName) {
      case 'DAYWEEK':
        setDayWeekErrors({});
        setDayWeekRows((prevRows) => ([
          ...prevRows,
          {
            id: prevRows[prevRows.length - 1].id + 1,
            startDayId: null,
            endDayId: null,
            allocation: 0,
            exclude: false,
          },
        ]));
        break;
      case 'DAYPART':
        setDayPartErrors({});
        setDayPartRows((prevRows) => ([
          ...prevRows,
          {
            id: prevRows[prevRows.length - 1].id + 1,
            daypart: '',
            daypartId: null,
            allocation: 0,
            exclude: false,
          },
        ]));
        break;
      default:
        break;
    };
  }, []);

  const handleOnRemoveRows = useCallback((rowName, indexForRemoval) => {
    if (!isCampaignActive) return;

    setIsFormTouched(true);

    switch (rowName) {
      case 'DAYWEEK':
        if (dayWeekRows.length !== 1) {
          setDayWeekErrors({});
          setDayWeekRows((prevRows) => prevRows.filter((row) => row.id !== indexForRemoval));
        }
        break;
      case 'DAYPART':
        if (dayPartRows.length !== 1) {
          setDayPartErrors({});
          setDayPartRows((prevRows) => prevRows.filter((row) => row.id !== indexForRemoval));
        }
        break;
      default:
        break;
    };
  }, [dayWeekRows, dayPartRows]);

  const handleOnKeyDownAddRows = (rowName, e) => {
    if (e.key === 'Enter' || e.key === 'Space') return handleOnAddRows(rowName);
  }

  const handleOnKeyDownRemoveRows = (rowName, id, e) => {
    if (e.key === 'Enter' || e.key === 'Space') return handleOnRemoveRows(rowName, id);
  }

  // Rendering Functions
  const renderDayWeekRows = useCallback(() => {
    return dayWeekRows.map((dayWeekRow, rowIndex) => {
      const { id, startDayId, endDayId, allocation, exclude } = dayWeekRow;
      const currentStartDay = dayWeekList?.find((day) => day.id === startDayId) || null;
      const currentEndDay = dayWeekList?.find((day) => day.id === endDayId) || null;

      return (
        <div key={`dayWeekRow${id}`}>
          <div className="day-week-rows">
            <div style={{ width: "100%", maxWidth: "210px" }}>
              <Select
                text={currentStartDay?.name || "Please Select"}
                list={dayWeekList}
                noSearch
                onSelect={(e) => handleOnDayWeekSelect('START', dayWeekRow, e)}
                width={"100%"}
                disabled={!isCampaignActive}
              />
            </div>
            <div style={{ width: "100%", maxWidth: "210px" }}>
              <Select
                text={currentEndDay?.name || "Please Select"}
                list={dayWeekList}
                noSearch
                onSelect={(e) => handleOnDayWeekSelect('END', dayWeekRow, e)}
                width={"100%"}
                disabled={!isCampaignActive}
              />
            </div>
            <Input
              value={allocation}
              maxLength={5}
              onChange={(e) => handleOnAllocationChange('DAYWEEK', id, e)}
              style={{ width: "100%", maxWidth: "85px", color: '#212529' }}
              disabled={exclude || !isCampaignActive}
            />
            <div className="exclude-switch-container">
              <Switch
                active={exclude}
                onSwitch={(isActive) => {handleOnExcludeChange('DAYWEEK', id, isActive)}}
                disabled={!isCampaignActive}
              />
            </div>
            {dayWeekRows.length > 1 && (
              <img
                src={trashcanGreenIcon}
                alt="trashcanGreenIcon"
                title={isCampaignActive ? "Remove day of week distrubtion" : undefined}
                className={`action-icon ${!isCampaignActive ? 'disabled' : ''}`}
                onClick={() => handleOnRemoveRows('DAYWEEK', id)}
                onKeyDown={(e) => handleOnKeyDownRemoveRows('DAYWEEK', id, e)}
                tabIndex={isCampaignActive ? 0 : -1}
              />
            )}
            {dayWeekRows.length - 1 === rowIndex && (
              <img
                src={plusCircleAltIcon}
                alt="plusCircleAltIcon"
                title={isCampaignActive ? "Add day of week distrubtion" : undefined}
                className={`action-icon ${!isCampaignActive ? 'disabled' : ''}`}
                onClick={() => handleOnAddRows('DAYWEEK')}
                onKeyDown={(e) => handleOnKeyDownAddRows('DAYWEEK', e)}
                tabIndex={isCampaignActive ? 0 : -1}
              />
            )}
          </div>
          {dayWeekErrors[id] && (
            <div className="d-flex gap-2 day-week-error-rows">
              <p className="w-100 is-danger capitalize" style={{ maxWidth: '210px'}}>
                {dayWeekErrors[id]?.startDay}
              </p>
              <p className="w-100 is-danger capitalize" style={{ maxWidth: '210px'}}>
                {dayWeekErrors[id]?.endDay}
              </p>
              <p className="w-100 is-danger capitalize" style={{ maxWidth: '85px'}}>
                {dayWeekErrors[id]?.allocation}
              </p>
            </div>
          )}
        </div>
      );
    });
  }, [
    dayWeekErrors,
    dayWeekRows,
    dayWeekList,
    handleOnDayWeekSelect,
    handleOnAllocationChange,
    handleOnAddRows,
    handleOnRemoveRows,
  ]);

  const renderDayPartRows = useCallback(() => {
    return dayPartRows.map((dayWeekRow, rowIndex) => {
      const { id, daypartId, allocation, exclude } = dayWeekRow;
      const currentDaypart = dayPartList?.find((item) => item.id === daypartId) || null;

      return (
        <div key={`dayPartRow${id}`}>
          <div className="day-week-rows">
            <div style={{ width: "100%", maxWidth: "210px" }}>
              <Select
                text={currentDaypart?.name || "Please Select"}
                list={dayPartList}
                noSearch
                onSelect={(e) => handleOnDayPartSelect(id, e)}
                width={"100%"}
                disabled={!isCampaignActive}
              />
            </div>
            <Input
              value={allocation}
              maxLength={5}
              onChange={(e) => handleOnAllocationChange('DAYPART', id, e)}
              style={{ width: "100%", maxWidth: "85px", color: '#212529' }}
              disabled={exclude || !isCampaignActive}
            />
            <div className="exclude-switch-container">
              <Switch
                active={exclude}
                disabled={!isCampaignActive}
                onSwitch={(isActive) => {handleOnExcludeChange('DAYPART', id, isActive)}}
              />
            </div>
            {dayPartRows.length > 1 && (
              <img
                src={trashcanGreenIcon}
                alt="trashcanGreenIcon"
                title={isCampaignActive ? "Delete daypart distribution" : undefined}
                className={`action-icon ${!isCampaignActive ? 'disabled' : ''}`}
                onClick={() => handleOnRemoveRows('DAYPART', id)}
                onKeyDown={(e) => handleOnKeyDownRemoveRows('DAYPART', id, e)}
                tabIndex={isCampaignActive ? 0 : -1}
              />
            )}
            {dayPartRows.length - 1 === rowIndex && (
              <img
                src={plusCircleAltIcon}
                alt="plusCircleAltIcon"
                title={isCampaignActive ? "Add daypart distribution" : undefined}
                className={`action-icon ${!isCampaignActive ? 'disabled' : ''}`}
                onClick={() => handleOnAddRows('DAYPART')}
                onKeyDown={(e) => handleOnKeyDownAddRows('DAYPART', e)}
                tabIndex={isCampaignActive ? 0 : -1}
              />
            )}
          </div>
          {dayPartErrors[id] && (
            <div className="d-flex gap-2 day-week-error-rows">
              <p className="w-100 is-danger capitalize" style={{ maxWidth: '210px'}}>
                {dayPartErrors[id]?.daypart}
              </p>
              <p className="w-100 is-danger capitalize" style={{ maxWidth: '85px'}}>
                {dayPartErrors[id]?.allocation}
              </p>
            </div>
          )}
        </div>
      );
    });
  }, [
    dayPartErrors,
    dayPartRows,
    dayPartList,
    handleOnDayPartSelect,
    handleOnAllocationChange,
    handleOnAddRows,
    handleOnRemoveRows,
  ]);

  return (
    <>
      <div className="d-flex flex-column gap-10">
        <div className="w-100 border-bottom d-flex">
          <span>Days of week (DoW)</span>
        </div>
      </div>

      <div className="w-100 d-flex flex-column gap-2">
        <div className="row-label">
          <p className="day-start">Start Day</p>
          <p className="day-end">End Day</p>
          <p className="day-allocation">%</p>
          <p className="day-exclude">Exclude</p>
        </div>
        <div className="rows-container">
          {renderDayWeekRows()}
        </div>
      </div>

      <div className="d-flex flex-column gap-10 align-items-center">
        <div className="w-100 border-bottom d-flex gap-2">
          <span>Daypart</span>
        </div>
      </div>

      <div className="w-100 d-flex flex-column gap-2">
        <div className="row-label">
          <p className="day-part">Name</p>
          <p className="day-allocation">%</p>
          <p className="day-exclude">Exclude</p>
        </div>
        <div className="rows-container">
          {renderDayPartRows()}
        </div>
      </div>
    </>
  );
};

export default DaySpendFields;