/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Formik, Form } from 'formik';
import WeeklySpendFields from './WeeklySpendFields';
import Button from '../../../shared/components/button/Button';
import {
  getTotalPercent,
  replaceWithInt,
  transformWeeklySpends,
} from '../helpers/helpers';
import useStore from '../hooks/useCampaignStore';
import {weeklySpendFormSchema, invalidTotal, invalidTotalMarket} from '../helpers/formSchema';
import Dialog from '../../Dialogs/Dialog';
import { updateWeeklyDistribution } from '../requests';
import UnsaveFormWarning from '../Dialog/UnsaveFormWarning';
import useHandleUnsavedForm from '../hooks/useHandleUnsavedForm';
import { preSubmitForm } from '../helpers/form';
import useDirtyForm from '../hooks/useDirtyForm';
import useWeeklySpend from "../hooks/useWeeklySpend";
import ConfirmLeaveModal from 'shared/components/confirm-leave-modal/ConfirmLeaveModal';
import { useCallbackPrompt } from 'components/_reusable/blocker/UsePromptComponent.ts';

const WeeklySpendForm = forwardRef(({}, ref) => {
  const {
    setShowCampaignForms,
    weeklySpendDates,
    isFormTouched,
    setIsFormTouched,
    setWeeklySpendDates,
    selectedCampaign,
    setIsFormSaved,
    isFormSaved,
    changeFormTab,
    setChangeFormTab,
    setNewCampaign,
    newCampaign,
    isCampaignActive,
    setActiveFormId
  } = useStore((state) => state);

  const [weeklySpendList, setWeeklySpendList] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [computedAllocation, setComputedAllocation] = useState(0);
  const [customErrorMsg, setCustomErrorMsg] = useState('');
  const [_, setProceedAndSave] = useState(false);

  useWeeklySpend(selectedCampaign?.startDate, selectedCampaign?.endDate);

  useHandleUnsavedForm(setShowDialog);

  const { handleProceed, handleCancel, handleDiscard } = useDirtyForm({
    setShowDialog,
    setProceedAndSave,
  });

  // Dirty forms
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation, handleHideNavigation] = useCallbackPrompt(
    isFormTouched
  );
  const [shouldExecuteSaveAndProceed, setShouldExecuteSaveAndProceed] = useState([false, () => {}]);
  const [proceedToNavigation, setProceedToNavigation] = useState(false);

  useEffect(() => {
    if (weeklySpendDates) {
      const transformedData = transformWeeklySpends(weeklySpendDates);

      setWeeklySpendList(transformedData);
      if (computedAllocation === 0) { setComputedAllocation(getTotalPercent(transformedData, 'allocation')); }
    }
  }, [weeklySpendDates]);

  useImperativeHandle(ref, () => ({
    submitForm(callbackFunction) {
      setShouldExecuteSaveAndProceed([true, callbackFunction]);
    },
  }));

  const handleNavigate = (proceed) => {
    if (!showConfirmLeaveModal && !shouldExecuteSaveAndProceed[0]) confirmNavigation();
    else {
      if (shouldExecuteSaveAndProceed[0]) {
        shouldExecuteSaveAndProceed[1]();
        setShouldExecuteSaveAndProceed([false, () => {}]);
      } else {
        setShowConfirmLeaveModal(false);
        if (proceed) handleCloseForm(true);
      }
    }
  };

  const onSubmit = async (values, { setErrors }) => {
    const totalAllocation = getTotalPercent(values.weeklySpend, 'allocation');
    const maxTotalAllocation = 100;
    const minTotalAllocation = 99;

    if (totalAllocation < minTotalAllocation || totalAllocation > maxTotalAllocation) {
      setComputedAllocation(totalAllocation);
      const { errors, msg } = invalidTotalMarket(values.weeklySpend, 'allocation');
      setCustomErrorMsg(msg);
      setErrors({ weeklySpend: errors });
      return;
    }
    const data = {
      weeklyDistributionParams: values.weeklySpend.map((val, index) => ({
        campaignId: selectedCampaign.id,
        weekNumber: index + 1,
        allocation: parseFloat(val.allocation || 0).toFixed(2),
      })),
    };
    setComputedAllocation(100);
    setCustomErrorMsg('');
    const res = await updateWeeklyDistribution(data, selectedCampaign.id);
    if (res.statusCode === 200) {
      setIsFormSaved(true);
      setWeeklySpendDates(values.weeklySpend);
      setShowDialog(true);
      setIsFormTouched(false);
    } else {
      handleNavigate(false);
    }
    setIsFormSaved(true);
    setChangeFormTab(false);
  };

  const handleClose = () => {
    setShowDialog(false);
    if (newCampaign && !proceedToNavigation) setActiveFormId('daySpend');
    if (proceedToNavigation) handleNavigate(true);
  };

  const handleCloseForm = (proceedFromNavigation) => {
    if (isFormTouched && !proceedFromNavigation) {
      setShowConfirmLeaveModal(true);
    } else {
      setShowCampaignForms(false);
      setShowDialog(false);
      setNewCampaign(false);
    }
  };

  return (
    <div className="d-flex w-100 gap-2 default-text" key={weeklySpendList}>
      <Formik
        initialValues={{ weeklySpend: weeklySpendList }}
        validationSchema={weeklySpendFormSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange: onChange,
          submitForm,
          setTouched,
          touched,
          validateForm,
          setErrors,
        }) => {
          const handleChange = (e) => {
            setTouched({ ...touched, [e.target.name]: e.target.value });
            setIsFormSaved(true);
            setIsFormTouched(true);
            e.target.value = replaceWithInt(e.target.value);
            onChange(e);
          };

          const handleOnValidate = (proceedFromDirtyForm) => {
            preSubmitForm({
              setIsFormSaved,
              validateForm,
              setErrors,
              setChangeFormTab,
              setProceedAndSave,
              showConfirmLeaveModal,
              setShowConfirmLeaveModal,
              cancelNavigation,
              proceedFromDirtyForm,
              submitForm,
            });
          };

          const handleOnHide = () => {
            if (showConfirmLeaveModal) setShowConfirmLeaveModal(false);
            else cancelNavigation();
          };
          
          const handleOnDiscard = () => {
            if (showConfirmLeaveModal) handleCloseForm(true);
            else {
              setNewCampaign(false);
              confirmNavigation();
            }
            setIsFormTouched(false);
            setProceedToNavigation(true);
          };
        
          const handleOnSaveAndProceed = () => {
            handleOnValidate(true);
            setProceedToNavigation(true);
            handleHideNavigation();
          };

          useEffect(() => {
            if (shouldExecuteSaveAndProceed[0]) handleOnSaveAndProceed();
          }, [shouldExecuteSaveAndProceed]);

          return (
            <Form
              className="w-100 d-flex flex-column gap-2 form"
              key={values.weeklySpend}
              noValidate
            >
              <Dialog
                onClose={handleClose}
                show={showDialog}
                Content={
                        changeFormTab && !isFormSaved ? (
                          <UnsaveFormWarning
                            handleProceed={() => handleProceed(submitForm, validateForm, setErrors)}
                            onCancel={handleCancel}
                            handleDiscard={handleDiscard}
                          />
                        ) : null
                      }
                isDirtyForm={changeFormTab && !isFormSaved}
              >
                Campaign {selectedCampaign?.name} has been updated.
              </Dialog>
              <div className="min-max-h-20 d-flex justify-content-center campagin-form customScroll">
                <div className="d-flex flex-column gap-10" style={{ width: '100%' }}>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="w-100 border-bottom d-flex gap-2">
                      <span className="w-274px">
                        Total weekly spend:
                        {' '}
                        {getTotalPercent(values.weeklySpend, 'allocation')}
                        %
                      </span>
                      <span className="w-85px d-flex justify-content-center">
                        %
                      </span>
                    </div>
                  </div>
                  <div className="w-100 d-flex gap-2">
                    {customErrorMsg && (
                    <span className="is-danger">{customErrorMsg}</span>
                    )}
                  </div>
                  <div className="d-flex flex-column gap-3 market-form-container mt-4 customScroll">
                    {weeklySpendList?.map((weeklySpend, key) => (
                      <WeeklySpendFields
                        key={key}
                        index={key}
                        values={values.weeklySpend}
                        errors={errors}
                        {...weeklySpend}
                        noWeek={key}
                        onChange={handleChange}
                        isCampaignActive={isCampaignActive}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="form-btn-container align-items-center d-flex gap-4 mt-5 justify-content-end" style={{ width: '95%' }}>
                <div>
                  <Button
                    text="Close"
                    onClick={() => handleCloseForm(false)}
                    defaultBtn
                  />
                </div>
                <div>
                  <Button
                    text="Save"
                    type="submit"
                    onClick={() => handleOnValidate(false)}
                    disabled={!isCampaignActive}
                  />
                </div>
              </div>

              <ConfirmLeaveModal
                show={(showPrompt || showConfirmLeaveModal) && !showDialog}
                onHide={handleOnHide}
                onSave={() => handleOnSaveAndProceed(handleOnValidate)}
                onDiscard={handleOnDiscard}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
})

export default WeeklySpendForm;
