export const gridRowSelectionHelper = (rowSelection, selectionColumnDef, enableMultipleRowSelection) => {
  return {
    rowSelectionProperties: {
      mode: enableMultipleRowSelection ? 'multiRow' : 'singleRow',
      checkboxes: false,
      headerCheckboxes: false,
      enableClickSelection: false,
      hideDisabledCheckboxes: false,
      ...rowSelection,
      enableSelectionWithoutKeys: false,
    },
    selectionColumnProperties: {
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      pinned: "right",
      headerClass: (params) => {
        const headerClasses = [];
        const hasHeaderName = params?.colDef?.headerName?.length > 0;

        if (!params?.headerName && !hasHeaderName) {
          headerClasses.push('hide-header-selection-label');
        }
        return headerClasses;
      },
      ...selectionColumnDef,
      sortable: false,
      suppressHeaderMenuButton: true,
    },
  }
};