export const gridRowSortHelper = (
  state,
  defaultSort,
  baseGridRef,
  columnDefinition,
  setAutoGroupColumnDefinition
) => {
  if (!state.sort && defaultSort) {
    // If column type is 'autoGroupColumn', assign default sort to auto group state
    if (defaultSort?.columnType && defaultSort?.columnType === 'autoGroupColumn') {
      setAutoGroupColumnDefinition((prevState) => ({
        ...prevState,
        sort: defaultSort?.sort || null,
      }));
      return;
    }

    // If column type is not defined, nor does it satisfy any above conditions,
    // map over normal column definitions as is
    const columnDefWithSort = columnDefinition.map((col) => {
      if (col.field === defaultSort.field) {
        col['sort'] = defaultSort.sort;
      }

      return col;
    });
    baseGridRef.current?.api?.setGridOption('columnDefs', columnDefWithSort);
  }
};
