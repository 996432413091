export const applyFilterParams = ({ columnInstance }) => {
   /**
    * This function is designed to configure filter parameters for each column in an ag-Grid configuration.
    * It iterates through the columns and applies specific filter parameters, such as enabling the mini filter to
    * apply while typing and displaying tooltips. This enhances the user experience by providing real-time
    * filtering feedback and additional information through tooltips, making data filtering more intuitive and
    * efficient.
    */

   const newColumnInstance = columnInstance.map((column) => {
    const isNumberType = column.cellDataType === 'number';
  
    const filterParams = {
      ...column.filterParams,
      applyMiniFilterWhileTyping: true,
      showTooltips: true,
      selectedValues: [],
      refreshValuesOnOpen: true,
    };
  
    // Apply custom filter comparator for number types
    if (isNumberType) {
      filterParams.comparator = numberSetFilter;
    }
  
    // Apply filterParams also if it has child columns
    if (column.children) {
      column.children = column.children.map((child) => ({
        ...child,
        filterParams: {
          ...child.filterParams,
          ...filterParams,
        },
      }));
    }
  
    return {
      ...column,
      filterParams,
      // Ensure that the filter value is always a string
      valueParser: (params) => String(params.newValue),
    };
  });

  return newColumnInstance;
};


export const numberSetFilter = (params) => {
  if (!params || !params.column) {
    return false;
  }

  // Get the value of the cell, either from valueGetter or directly from the data
  const valueGetter = params.column.colDef.valueGetter;
  const value = valueGetter ? valueGetter(params) : params.data[params.column.field];

  if (!value || typeof value !== 'number') {
    return false;
  }

  const selectedValues = params.filterParams.selectedValues;

  // Check if the value exists in the selected values (Set Filter's value list)
  return selectedValues.includes(value);
}
