import React from "react";
import '../../campaigns.css';
import { notifyStationsIcon } from "assets";
import useStore from "components/campaigns/hooks/useCampaignStore";
import { OpDataGrid } from "op2mise-react-widgets";
import Button from "shared/components/button/Button";
import ComposeEmailForm from "./ComposeEmailForm";
import NotifyAllMarketsModal from "./NotifyAllMarketsModal";
import NotifySelectedMarketModal from "./NotifySelectedMarketModal";
import SuccessfullyNotifiedModal from "./SuccessfullyNotifiedModal";
import { getMarkets } from "components/campaigns/requests";

const NotificationsForm = ({  }) => {
    const {
        // markets,
        selectedCampaign,
        setNewCampaign,
        setShowCampaignForms,
        isCampaignActive
    } = useStore((state) => state);
    const [selectedMarketId, setSelectedMarketId] = React.useState(null);
    const [selectedStations, setSelectedStations] = React.useState([]);
    const [showComposeEmailForm, setShowComposeEmailForm] = React.useState(false);
    const [showNotifyAllMarketsForm, setShowNotifyAllMarkets] = React.useState(false);
    const [showSuccessfullyNotifiedModal, setShowSuccessfullyNotifiedModal] = React.useState(false);
    const [markets, setMarkets] = React.useState([]);

    const handleCloseForm = () => {
        setNewCampaign(false);
        setShowCampaignForms(false);
    };

    const handleGetMarkets = async () => {
        const res = await getMarkets(selectedCampaign.id, '');
        setMarkets(res[0].marketDistributionListingResults);
    };

    const handleGetStations = async (event, params) => {
        if (!isCampaignActive) return;

        event.stopPropagation();
        setSelectedMarketId(params.data.marketId);
        
    };

    const handleShowComposeEmailForm = () => {
        setSelectedMarketId(null);
        setShowComposeEmailForm(true);
        setShowNotifyAllMarkets(false);
    };

    // Op data grid settings
    const statusColumnValueFormatter = (params) => {
        const marketStatus = {
          N: 'New',
          A: 'Avails',
          D: 'Draft',
          F: 'Final',
        };
    
        return marketStatus[params.value];
    };

    const headers = ['marketName', 'status', 'availsPending'];

    const columnDefinitions = React.useMemo(() => [
        { headerName: 'MARKET', field: 'marketName' },
        {
            headerName: 'STATUS',
            field: 'status',
            filterParams: { valueFormatter: statusColumnValueFormatter },
            valueFormatter: statusColumnValueFormatter,
        },
        { headerName: 'AVAILS', field: 'availsPending' },
        {
            cellClass: 'ag-text-align-center suppress-cell-selection-border',
            cellRenderer: (params) => {
                return (
                <img
                    src={notifyStationsIcon}
                    className={`notify-stations-icon ${!isCampaignActive ? 'disabled' : ''}`}
                    title="Notify Stations"
                    alt="notify-stations"
                    onClick={(event) => handleGetStations(event, params)}
                />
                )
            },
            headerName: ' ',
            field: 'action',
            suppressNavigable: true,
            width: 80,
        },
    ], [handleGetStations]);

    React.useEffect(() => {
        handleGetMarkets();
    }, []);

    return (
        <>
            <div className={'d-flex justify-content-center w-100'}>
                <div style={{ marginTop: '68px', width: '75%' }}>
                    <OpDataGrid
                        columns={columnDefinitions}
                        customVerbiage={'No Markets Found'}
                        defaultSort={{ field: 'marketName', sort: 'asc' }}
                        gridHeightBuffer={412}
                        gridName={'mp-campaign-form-notification'}
                        resizable={true}
                        rows={markets}
                        sharedColumnProperties={{
                            filter: { fields: headers, value: true },
                            flex: { fields: headers, value: 1 },
                            sortable: { fields: headers, value: true },
                        }}
                        showAlternativeRowHighlight={true}
                        suppressRowClickSelection={true}
                    />
                </div>
            </div>

            <div
                className={'d-flex justify-content-end'}
                style={{ paddingRight: '153px', marginTop: '56px', marginBottom: '32px' }}
            >
                <Button defaultBtn onClick={handleCloseForm} text={'Close'} />
                <Button
                    text={'Notify All Stations'}
                    onClick={() => setShowNotifyAllMarkets(true)}
                    disabled={!isCampaignActive}
                />
            </div>

            {selectedMarketId && (
                <NotifySelectedMarketModal
                    onHide={() => setSelectedMarketId(null)}
                    onSelectStations={setSelectedStations}
                    selectedCampaign={selectedCampaign}
                    selectedMarketId={selectedMarketId}
                    setShowComposeEmailForm={handleShowComposeEmailForm}
                />
            )}

            {showNotifyAllMarketsForm && (
                <NotifyAllMarketsModal
                    onHide={() => setShowNotifyAllMarkets(false)}
                    onSelectStations={setSelectedStations}
                    selectedCampaign={selectedCampaign}
                    setShowComposeEmailForm={handleShowComposeEmailForm}
                />
            )}

            {showComposeEmailForm && (
                <ComposeEmailForm
                    onHide={setShowComposeEmailForm}
                    selectedCampaign={selectedCampaign}
                    selectedStations={selectedStations}
                    setShowSuccessfullyNotifiedModal={setShowSuccessfullyNotifiedModal}
                />
            )}

            {showSuccessfullyNotifiedModal && (
                <SuccessfullyNotifiedModal
                    onHide={() => setShowSuccessfullyNotifiedModal(false)}
                    selectedStations={selectedStations}
                />
            )}
        </>
    );
};

export default NotificationsForm;