/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import * as Yup from 'yup';
import { isNaN } from 'lodash';
import moment from 'moment';

const errMsgs = {
  numberOnly: 'Should only contain number',
  max30char: 'Should not be greater than 30 characters',
  max10Int: 'Should not be greater than 10 integers',
  max9Int: 'Should not be greater than 9 integers',
  max4Int: 'Should not be greater than 4 integers',
  minDec: 'Should not be less than 0.1',
  maxDec: 'Should not be exceed 100',
  invaliteDate: 'Invalid date',
  invalidStartDate: 'Start date should not be after or equal to end date',
  invalidEndDate: 'End date should not be before or equal to start date',
  invalidDate: "Invalid date.",
  required: 'Field cannot be empty',
  max5: 'Field must be at most 5 characters',
  invalidTotal: 'Total should not exceed 100%',
  invalidTotalMarket: 'Total should equal 100%',
  beyondMaxProgramme: 'Should not exceed 99',
};

const zeros = ['0.0', '0.', '0'];

const regExNum = /^[0-9]\d*(\.\d+)?$/;
const validateMaxDec = (val) => {
  const parseVal = parseFloat(val);
  if (isNaN(parseVal) || parseVal > 100) return false;
  return true;
};

const getDateErrorMsg = (key) => (key === 'startDate' ? errMsgs.invalidStartDate : errMsgs.invalidEndDate);

const validateDate = (val, cntx) => {
  const values = cntx.from[0].value;
  const start = values.startDate;
  const end = values.endDate;
  const isAfter = moment(start).isAfter(end);

  if (isAfter) {
    return cntx.createError({ message: getDateErrorMsg(cntx.path) });
  }

  return val;
};

  const validStartDate = (val, cntx) => {
    const values = cntx.from[0].value;
    const start = values.startDate;
    const minDate= new Date('01-01-1900');
    const maxDate=new Date('01-01-2200');

    if ( moment(start).isAfter(maxDate) || moment(start).isBefore(minDate)) {
      return cntx.createError({ message: errMsgs.invalidDate });
    }

    return val;
  }

const validEndDate = (val, cntx) => {
  const values = cntx.from[0].value;
  const end = values.endDate;
  const minDate= new Date('01-01-1900');
  const maxDate=new Date('01-01-2200');

  if ( moment(end).isAfter(maxDate) || moment(end).isBefore(minDate)) {
    return cntx.createError({ message: errMsgs.invalidDate });
  }

  return val;
}

export const invalidTotal = (values, key) => ({
  errors: values.map(() => ({
    [key]: true,
  })),
  msg: errMsgs.invalidTotal,
});

export const invalidTotalMarket = (values, key) => ({
  errors: values.map(() => ({
    [key]: true,
  })),
  msg: errMsgs.invalidTotalMarket,
});

// const validateTotalPercentage = (val, cntx) => {
//   const { markets, weeklySpend } = cntx.from[1].value;
//   const total = getTotalPercent(markets ?? weeklySpend, 'percentage');
//   if (total !== 100) {
//     return cntx.createError({ message: 'wrong' });
//   }
//   return val;
// };

export const campaignHeaderFormSchema = Yup.object({
  name: Yup.string().required(),
  // startDate: Yup.date().test('date', validateDate).test('date', validStartDate).required(),
  // endDate: Yup.date().test('date', validateDate).test('date', validEndDate).required(),
  budget: Yup.string().matches(regExNum, errMsgs.numberOnly).max(10, errMsgs.max10Int).nullable(),
  frequency: Yup.string().notOneOf(zeros, errMsgs.minDec).nullable(),
  reach: Yup.string().notOneOf(zeros, errMsgs.minDec).nullable(),
  costPerPoint: Yup.string().matches(regExNum, errMsgs.numberOnly)
    .max(4, errMsgs.max4Int).nullable(),
  rating: Yup.string().matches(regExNum, errMsgs.numberOnly)
    .max(9, errMsgs.max9Int).nullable(),
  maxSpotsPerProgram: Yup.string().matches(regExNum, errMsgs.numberOnly)
    .max(4, errMsgs.max4Int).nullable(),
  maxSpotsPerProgramPerWeek: Yup.string().matches(regExNum, errMsgs.numberOnly)
    .max(4, errMsgs.max4Int).nullable(),
});


export const marketSpendFormSchema = Yup.object({
  markets: Yup.array().of(
    Yup.object().shape({
      allocation: Yup.string().nullable(),
      budgetAmount: Yup.string().nullable(),
      frequency: Yup.string().nullable(),
      reach: Yup.string().nullable(),
      costPerPoint: Yup.string().nullable(),
      targetRating: Yup.string().nullable(),
    }),
  ),
});

export const weeklySpendFormSchema = Yup.object({
  weeklySpend: Yup.array().of(
    Yup.object().shape({
      allocation: Yup.string()
        .max(5, errMsgs.max5),
    }),
  ),
});
