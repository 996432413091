import axios from 'axios';

const request = async ({ endpoint, method = 'GET', ...props }) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const userIdentity = JSON.parse(sessionStorage.getItem('userIdentity'));
    const response = await axios({
      method,
      url: `${process.env.REACT_APP_HOST_URL}${endpoint}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userIdentity.token}`,
      },
      responseType: 'text',
      ...props,
    });

    return response.data;
  } catch (error) {
    // Handle errors, log them, or perform error-specific logic here.
    throw error;
  }
};

export default request;
