/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-useless-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react/react-in-jsx-scope */
import React, {
 useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Formik, Form } from 'formik';
import '../campaigns.css';
import {capitalize, isEmpty, range} from 'lodash';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'op2mise-react-widgets/Modal/Modal';
import InputLabel from '../../Forms/InputLabel';
import DatePicker from "react-datepicker";
import '../../../assets/css/custom-date-picker.css';
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import moment from "moment";

import {
  op2miseOptions,
  initialFields,
  formatDate,
  transformWeeklySpendRange,
  transformAudiences,
  checkAudienceValue,
  isIntKey,
  replaceWithInt,
  trimStringWithWhitespace,
  allow1Decimal, formatDecimal, transformWeeklySpends, allow2Decimals,
  validateFields,
  getCampaignFlightStatus,
} from '../helpers/helpers';
import Switch from '../../Forms/Switch';
import Button from '../../../shared/components/button/Button';
import useStore from '../hooks/useCampaignStore';
import { updateCampaign, addCampaign, notifyStations } from '../requests';
import useTabSelector from '../hooks/useTabSelector';
import useWeeklySpend, { getTotalWeeks } from '../hooks/useWeeklySpend';
import {
  campaignHeaderFormSchema,
} from '../helpers/formSchema';
import Dialog from '../../Dialogs/Dialog';
import CampaignContent from '../Dialog/CampaignContent';
import UnsaveFormWarning from '../Dialog/UnsaveFormWarning';
import useHandleUnsavedForm from '../hooks/useHandleUnsavedForm';
import { fetchDefaultDateById, preSubmitForm } from '../helpers/form';
import useSidenavStore from '../../../shared/sidebar/hooks/useSidenavStore';
import useDirtyForm from '../hooks/useDirtyForm';
import SelectComponent from '../../../shared/elements/select/select.component';
import { useCallbackPrompt } from 'components/_reusable/blocker/UsePromptComponent.ts';
import ConfirmLeaveModal from 'shared/components/confirm-leave-modal/ConfirmLeaveModal';
import NotifyStationModal from '../Dialog/NotifyStationModal';

/* eslint-disable */
const CampaignHeaderForm = forwardRef(({}, ref) => {
  const {
    setShowCampaignForms,
    isFormTouched,
    setIsFormTouched,
    selectedCampaign,
    changeFormTab,
    setSelectedCampaign,
    setActiveFormId,
    setIsFormSaved,
    isFormSaved,
    setChangeFormTab,
    markets,
    setMarkets,
    setWeeklySpendDates,
    dateFormat,
    setDateFormat,
    setCurrencyCode,
    newCampaign,
    setNewCampaign,
    fields,
    setFields,
    weeklySpendDates,
    campaignInFlight,
    setCampaignInFlight,
    isCampaignActive,
    setIsCampaignActive,
  } = useStore((state) => state);
  const navigate = useNavigate();
  const { toPath, setIsNavClicked } = useSidenavStore((state) => state);
  const previousAudiences = useRef(markets.audiences);

  const [switchOpt, setSwitchOpt] = useState(op2miseOptions);
  const [showDialog, setShowDialog] = useState(false);
  const [created, setCreated] = useState(false);
  const [proceedAndSave, setProceedAndSave] = useState(false);
  const [defaultWeekly, setdefaultWeekly] = useState(false);
  const [defaultMarket, setdefaultMarket] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [isNewCampaign, setIsNewCampaign] = useState(false);
  const [audienceList, setAudienceList] = useState([]);
  const [isCampaignComplete, setIsCampaignComplete] = useState(false);
  const [isCampaignSaving, setIsCampaignSaving] = useState(false);
  const typeList = [
    {id: 0, name: 'TV'},
    {id: 1, name: 'CTV'},
    {id: 2, name: 'Radio'},
  ];

  // State used to store old campaign data for backend comparison in field changes
  const [previousCampaignData, setPreviousCampaignData] = useState({});

  // Modal States
  const [showCampaignSavedModal, setShowCampaignSavedModal] = useState(false);
  const [showCampaignNotificationModal, setShowCampaignNotificationModal] = useState(false);
  const [noActiveContactMessage, setNoActiveContactMessage] = useState('');
  const [isNotficationSending, setIsNotificationSending] = useState(false);

  // Dirty forms
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation, handleHideNavigation] = useCallbackPrompt(
    isFormTouched
  );
  const [proceedToNavigation, setProceedToNavigation] = useState(false);
  const [shouldExecuteSaveAndProceed, setShouldExecuteSaveAndProceed] = useState([false, () => {}]);

  useImperativeHandle(ref, () => ({
    submitForm(callbackFunction) {
      setShouldExecuteSaveAndProceed([true, callbackFunction]);
    },
  }));

  useWeeklySpend(fields.startDate, fields.endDate);

  useHandleUnsavedForm(setShowDialog);

  const {
    handleProceed, handleCancel, handleDiscard, gotoNav,
  } = useDirtyForm({
    setShowDialog,
    setProceedAndSave,
  });

  useEffect(() => {
    setFields(initialFields);
    if (markets.audiences) {
      setAudienceList(transformAudiences(markets.audiences));
    }
    if (JSON.stringify(markets.audiences) !== JSON.stringify(previousAudiences.current)) {
      setSwitchOpt(op2miseOptions);
      previousAudiences.current = markets.audiences;
    }
  }, [markets.audiences]);


  const convertType = (value, key) => {
    if (key === 'id') {
      const typeItem = typeList.find(item => item.id === value);
      return typeItem ? typeItem.name : '';
    }

    if (key === 'name') {
      const typeItem = typeList.find(item => item.name === value);
      return typeItem ? typeItem.id : '';
    }

    return null;
  };

  const handleCampaignStatus = (campaignStatus) => {
    if (typeof campaignStatus === 'boolean') {
      setIsCampaignActive(campaignStatus);
    } else {
      setIsCampaignActive(campaignStatus === 'A');
      setIsCampaignComplete(campaignStatus === 'C');
    }
  };

  useEffect(() => {
    /*
     * Store previous selectedCampaign data only on initial render. To be used
     * by backend to determine changes when editing campaigns to send notifications
    */
    if (selectedCampaign) setPreviousCampaignData({
      ...selectedCampaign,
      status: ['A', 'a', true].some((s) => s === selectedCampaign.status),
    });
  }, [])

  useEffect(() => {
    if (selectedCampaign) {
      setFields({
        ...selectedCampaign,
        startDate: selectedCampaign.startDate,
        endDate: selectedCampaign.endDate,
        dueDate: selectedCampaign.dueDate,
        objective: {
          ...op2miseOptions.filter(option => option.value === selectedCampaign.objective)[0],
          active: true,
        },
        status: ['A', 'a', true].some((s) => s === selectedCampaign.status),
        audience: selectedCampaign.audience ?? 'Please select',
        type: convertType(selectedCampaign.type, 'id'),
      });
      setAudienceList(transformAudiences(selectedCampaign.audiences));
      setSwitchOpt(
          op2miseOptions.map((opt, key) => ({
            ...opt,
            active: opt.value === selectedCampaign.objective,
          })),
      );
      handleCampaignStatus(selectedCampaign.status);
    }

    if (!selectedCampaign) {
      return setIsNewCampaign(true);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCampaign) {
      // get campaign flight status if campaign is selected
      setCampaignInFlight(getCampaignFlightStatus(selectedCampaign.startDate, selectedCampaign.endDate));
    }
    // Check if campaign is newly created or a campaign is not selected
    if (created || !selectedCampaign) {
      setCampaignInFlight(false);
    }
  }, [selectedCampaign, created])

  useTabSelector(fields.startDate, fields.endDate, fields.budget);

  const handleNavigate = (proceed) => {
    if (!showConfirmLeaveModal && !shouldExecuteSaveAndProceed[0]) confirmNavigation();
    else {
      if (shouldExecuteSaveAndProceed[0]) {
        shouldExecuteSaveAndProceed[1]();
        setShouldExecuteSaveAndProceed([false, () => {}]);
      } else {
        setShowConfirmLeaveModal(false);
        if (proceed) handleClose(true);
      }
    }
  };

  const handleSwitch = (_, option) => {
    if (!isCampaignActive) return;

    setdefaultMarket(true);
    setSwitchOpt(
        switchOpt.map((opt) => ({
          ...opt,
          active: opt.label === option.label,
        })),
    );
    setFields({ ...fields, objective: { ...option, active: true } });
    setIsFormTouched(true);
  };

  const handleFormModalDisplay = (fields) => {
    if (isNewCampaign) return setShowCampaignSavedModal(true);

    const {
      name,
      startDate,
      endDate,
      audience, 
      status
    } = previousCampaignData;
    // Check if any of the fields are edited or when status is edited from true to false
    const isFieldsEdited = (name !== fields.name)
      ||  formatDate(fields.startDate) < (formatDate(startDate))
      ||  formatDate(fields.endDate) > (formatDate(endDate))
      || (audience !== fields.audience)
      || (status !== fields.status && !fields.status);

    if (!isNewCampaign && !isFieldsEdited) {
      return setShowCampaignSavedModal(true);
    }

    return setShowCampaignNotificationModal(true);
  };

  // Campaign Form Submission Logic
  const onSubmit = async (
      {
        initial, status, audiences, ...values
      },
      { setErrors },
  ) => {

    const validationRes = validateFields(fields, values, campaignInFlight, selectedCampaign);
    if (Object.keys(validationRes).length > 0) {
      setErrors(validationRes);
      cancelNavigation();
      setIsNavClicked(false);
      return;
    }
    const incrementStartDate = new Date(values.startDate);
    const incrementEndDate = new Date(values.endDate);
    incrementStartDate.setDate(incrementStartDate.getDate() + 1);
    incrementEndDate.setDate(incrementEndDate.getDate() + 1);

    let fieldsCopy = {
      ...values, 
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      dueDate: formatDate(values.dueDate),
      objective: fields.objective.value,
      reach: isNaN(parseFloat(values.reach)) ? null : parseFloat(values.reach).toFixed(2),
      frequency: isNaN(parseFloat(values.frequency)) ? null : parseFloat(values.frequency).toFixed(1),
      costPerPoint: parseInt(values.costPerPoint) || null,
      rating: parseInt(values.rating) || null,
      maxSpotsPerProgram: parseInt(values.maxSpotsPerProgram) || null,
      maxSpotsPerProgramPerWeek: parseInt(values.maxSpotsPerProgramPerWeek) || null,
      budget: parseInt(values.budget) || null,
      noWeeks: getTotalWeeks(values.startDate, values.endDate),
      toggleStatus: status,
      resetDefaultMarket: defaultMarket,
      resetDefaultWeekly: defaultWeekly,
      audienceId: audienceList.find(({ name }) => name === fields.audience).id,
      audience: fields.audience,
      type: convertType(fields.type, 'name'),
    };

    if (!selectedCampaign) setCreated(true);
    setIsFormSaved(true);
    setIsCreate(!selectedCampaign);
    setIsCampaignSaving(true);
    const result = (await selectedCampaign)
        ? updateCampaign(fieldsCopy, fields.id)
        : addCampaign(fieldsCopy);
    result
        .then((res) => {
          if (!res.id) {
            return;
          }
          const {
            id,
            marketDistributionResults,
            weeklyDistributionResults,
            startDate,
          } = res;
          fieldsCopy = {
            ...fieldsCopy,
            startDate: values.startDate,
            endDate: values.endDate,
          }
          setSelectedCampaign({
            ...fieldsCopy,
            status,
            audiences: audienceList,
            ...(selectedCampaign ? {} : { id }),
          });
          setMarkets(marketDistributionResults);
          setWeeklySpendDates(
              transformWeeklySpends(weeklyDistributionResults),
          );

          setCurrencyCode(res.currencyCode);
          setDateFormat(res.dateformat);
          setIsFormSaved(true);
          setIsFormTouched(false);
          // handleFormModalDisplay({
          //   ...fieldsCopy,
          //   status,
          // });
          // Temporarily show campaign has been saved modal for now
          setShowCampaignSavedModal(true)
          if (proceedToNavigation) setNewCampaign(false);
        })
        .catch(() => {
          setShowCampaignSavedModal(false);
          setShowCampaignNotificationModal(false);
          handleNavigate(false);
        })
        .finally(() => {
          setIsCampaignSaving(false);
        });

    setIsFormSaved(true);

    // setChangeFormTab(newCampaign);
  };

  // close button for campaign
  const handleClose = (proceed) => {
    if (isFormTouched && !proceed) {
      setShowConfirmLeaveModal(true);
    } else {
      setShowCampaignForms(false);
      setNewCampaign(false);
      setIsFormTouched(false);
      setShowConfirmLeaveModal(false);
    }
  }

  const isNotTouched = (errors, touched) => {
    if (
        isEmpty(errors)
        && isEmpty(touched)
        && !isFormSaved
        && !fields.initial
    ) {
      setIsFormSaved(true);
    }
  };

  // Modal helper functions
  const handleCloseCampaignSavedModal = () => {
    setPreviousCampaignData(selectedCampaign);
    setShowCampaignSavedModal(false);
    setIsFormTouched(false);
    if (proceedToNavigation) handleNavigate(true);
    setChangeFormTab(newCampaign);
    if (isCreate && !proceedToNavigation) setActiveFormId('marketSpend');
  };

  const handleCloseCampaignNotificationModal = () => {
    setPreviousCampaignData(selectedCampaign);
    setShowCampaignNotificationModal(false);
    setIsFormTouched(false);
    setChangeFormTab(newCampaign);
    if (proceedToNavigation) handleNavigate(true);
  };

  const handleProceedCampaignNotificationModal = async () => {
    let notifyStationData = {
      campaignId: selectedCampaign.id,
      isNewCampaign: false,
      parameters: {
        ...previousCampaignData,
        startDate: formatDate(previousCampaignData.startDate),
        endDate: formatDate(previousCampaignData.endDate),
        dueDate: formatDate(previousCampaignData.dueDate),
        status: selectedCampaign.status, // send updated status value to backend as required
        toggleStatus: selectedCampaign.status,
      },
    };

    setIsNotificationSending(true);
    try {
      const res = await notifyStations(notifyStationData, selectedCampaign.id)
      setPreviousCampaignData(selectedCampaign);
      setShowCampaignNotificationModal(false);
      setChangeFormTab(newCampaign);
      setIsNotificationSending(false);
      if (proceedToNavigation) handleNavigate(true);
    } catch (error) {
      console.error(error)
      setShowCampaignNotificationModal(false);
      setIsNotificationSending(false);
      if (error.response.data.message && error.response.status === 400) {
        setNoActiveContactMessage(error.response.data.message);
      }
    }
  };

  const handleCloseNoActiveContactModal = () => {
    setPreviousCampaignData(selectedCampaign);
    setNoActiveContactMessage('');
    setIsFormTouched(false);
    setChangeFormTab(newCampaign);
    if (proceedToNavigation) handleNavigate(true);
  }

  const handleSelectAudience = (val) => {
    setFields({
      ...fields,
    audience: val.name,
    });
    setIsFormTouched(true);
};

  const handleSelectType = (val) => {
    setFields({
      ...fields,
      type: val.name,
    });
    setIsFormTouched(true);
  };

  return <div className="d-flex w-100 gap-3 default-text" key={fields.startDate}>
    <Formik
        initialValues={fields}
        validationSchema={campaignHeaderFormSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
    >
      {({
          values,
          handleChange: onChange,
          errors,
          touched,
          setTouched,
          submitForm,
          validateForm,
          setErrors,
          setFieldValue,
        }) => {
        const [startDate, setStartDate] = useState(null);
        const [startDateError, setStartDateError] = useState(null);
        const [endDate, setEndDate] = useState(null);
        const [endDateError, setEndDateError] = useState(null);
        const [dueDate, setDueDate] = useState(null);
        const years = range(1990, 2200);
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        if (isEmpty(touched)) isNotTouched(errors, touched);
        const handleChange = (event) => {
          const e = { ...event };
          let val = e.target.value;
          const numericOnly = [
            'budget',
            'costPerPoint',
            'rating',
            'maxSpotsPerProgram',
            'maxSpotsPerProgramPerWeek',
          ];
          const decimalOnly = ['reach', 'frequency'];

          val = val.replace(/^0+/, '');

          if (val === '.00' && e.target.name === 'reach')  {
            return
          }

          if (val === '.0' && e.target.name === 'frequency')  {
            return
          }          

          if (numericOnly.includes(e.target.name) && val.includes('.')) {
            e.target.value = val.replace('.', '0');
            return
          }


          if (e.nativeEvent.inputType === 'insertFromPaste') {
            if (numericOnly.includes(e.target.name)) {
              // eslint-disable-next-line no-use-before-define
              e.target.value = handleTrimNumeric(e.target.value);
            }
          } else if (e.nativeEvent.inputType === 'insertText') {
            if (numericOnly.includes(e.target.name)) {
              e.target.value = val.replace(/[^0-9]/g, '');
            }
          }

          if (decimalOnly.includes(e.target.name)) {
            const maxValue = 100;

            if (val > maxValue) return;
            val = val.replace('..', '.');
          }

          if (numericOnly.includes(e.target.name) || decimalOnly.includes(e.target.name)) {
            e.target.value = val.replace(/^00+/, 0);
          }

          if (val.includes('.') && decimalOnly.includes(e.target.name))  {
            const valSplit = val.split('.');
            if (valSplit[0] === '') e.target.value = `0${val}`;
          }

          setTouched({ ...touched, [e.target.name]: e.target.value });
          setIsFormSaved(true);
          if (e.target.name === 'startDate' || e.target.name === 'endDate') {
            setdefaultWeekly(true);
            setdefaultMarket(true);
          }

          if (['budget', 'frequency', 'reach', 'costPerPoint', 'rating'].includes(e.target.name)) {
            setdefaultMarket(true);
          }


          if (e.target.name === 'status' && !selectedCampaign) return;
          setIsFormSaved(true);

          if (isIntKey(e.target.name)) {
            e.target.value = replaceWithInt(e.target.value);
          }

          onChange(e);
          setIsFormTouched(true);
        };

        const handleTrimNumeric = (val) => {
          if (val.includes('.')) {
            // eslint-disable-next-line no-param-reassign,prefer-destructuring
            val = val.split('.')[0];
          }
          return replaceWithInt(val.replace(/\D/g, ''));
        };

        fetchDefaultDateById('startDate', dateFormat);
        fetchDefaultDateById('endDate', dateFormat);

        const handleDecimal = (e) => {
          const val = e.target.value;
          e.target.value = replaceWithInt(allow1Decimal(e, val));

          handleChange(e);
        };

        const handleReachInput = (e) => {
          const val = e.target.value;
          e.target.value = replaceWithInt(allow2Decimals(e, val));
          handleChange(e);
        };

        const handleOnValidate = (proceedFromDirtyForm) => {

          preSubmitForm({
            setErrors,
            validateForm,
            setChangeFormTab,
            setProceedAndSave,
            setIsFormSaved,
            showConfirmLeaveModal,
            setShowConfirmLeaveModal,
            cancelNavigation,
            proceedFromDirtyForm,
            submitForm,
          });
        };

        const handleOnHide = () => {
          if (showConfirmLeaveModal) setShowConfirmLeaveModal(false);
          else cancelNavigation();

          setIsNavClicked(false);
        };

        const handleOnDiscard = () => {
          if (showConfirmLeaveModal) handleClose(true);
          else {
            setNewCampaign(false);
            confirmNavigation();
          }
          setIsFormTouched(false);
          setProceedToNavigation(true);
        };

        const handleOnSaveAndProceed = () => {
          handleOnValidate(true);
          handleHideNavigation();
          setProceedToNavigation(true);
          if (Object.keys(errors).length) {
            setIsNavClicked(false);
          }
        };

        useEffect(() => {
          if (shouldExecuteSaveAndProceed[0]) handleOnSaveAndProceed();
        }, [shouldExecuteSaveAndProceed]);

        const handleStartDate = (date) => {
          setStartDate(date);
          setTouched({ ...touched, startDate: date });
          setFieldValue(
              'startDate',
              date
          );
          setdefaultWeekly(true);
          setdefaultMarket(true);
          setIsFormSaved(true);
          setIsFormTouched(true);
        };

        const handleEndDate = (date) => {
          setEndDate(date);
          setTouched({ ...touched, endDate: date });
          setFieldValue(
              'endDate',
              date
          );
          setdefaultWeekly(true);
          setdefaultMarket(true);
          setIsFormSaved(true);
          setIsFormTouched(true);
        };

        const handleDueDate = (date) => {
          setDueDate(date);
          setTouched({ ...touched, dueDate: date });
          setFieldValue(
              'dueDate',
              date
          );
          setdefaultWeekly(true);
          setdefaultMarket(true);
          setIsFormSaved(true);
          setIsFormTouched(true);
        };

        useEffect(() => {
          if (values.startDate) {
            setStartDate(new Date(values.startDate));
            setEndDate(new Date(values.endDate));
          }
          if (values.dueDate) {
            setDueDate(new Date(values.dueDate));
          }
        }, []);

        return <Form
                className="w-100 d-flex flex-column gap-2 form"
                key={values}
                noValidate
            >
              <Dialog
                  show={showCampaignSavedModal}
                  onClose={handleCloseCampaignSavedModal}
                  Content={
                    changeFormTab && !isFormSaved ? (
                        <UnsaveFormWarning
                            handleProceed={() => handleProceed(submitForm, validateForm, setErrors)}
                            onCancel={handleCancel}
                            handleDiscard={handleDiscard}
                        />
                    ) : null
                  }
                  isDirtyForm={changeFormTab && !isFormSaved}
              >
                <CampaignContent
                  name={fields.name}
                  created={created}
                  campaignInFlight={campaignInFlight}
                />
              </Dialog>
              <NotifyStationModal
                show={showCampaignNotificationModal}
                content={
                  <p className="text-center">Campaign has been successfully edited.<br/>
                  Would you like to notify the stations?</p>
                }
                onClose={handleCloseCampaignNotificationModal}
                onProceed={handleProceedCampaignNotificationModal}
                isActionPending={isNotficationSending}
              />
              <Dialog
                show={noActiveContactMessage}
                onClose={handleCloseNoActiveContactModal}
              >
                {noActiveContactMessage}
              </Dialog>
              {/* Main Form Container */}
              <div className="min-max-h-20 d-flex justify-content-center campagin-form customScroll">
                <div className="d-flex flex-column gap-10" style={{ width: '95%' }}>
                  <div className="d-flex gap-2">
                    <InputLabel
                        maxLength={30}
                        errors={errors}
                        onBlur={(e) => {
                          e.target.value = e.target.value.replace(/\s+/g, ' ').trim();
                          if (typeof onChange === 'function') onChange(e);
                          if (typeof onBlur === 'function') onBlur();

                          setFieldValue(
                              'name',
                              e.target.value
                          );
                        }}
                        label="Campaign Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Enter the Campaign name"
                        disabled={!isCampaignActive}
                        required
                    />
                    <div className="col-md-4" style={{ paddingLeft: '16px' }}>
                      <div className="d-flex flex-column w-100 gap-5">
                        <span className="input-label">
                          Type
                          <span className="input-required">*</span>
                        </span>
                        <SelectComponent
                          text={fields.type}
                          list={typeList.filter((o) => o.id !== 1)}
                          onSelect={handleSelectType}
                          width="100%"
                          height="250px"
                          disabled={selectedCampaign}
                        />
                        {errors.type && (
                          <p className="is-danger capitalize">
                            {errors.type}
                          </p>
                        )}
                      </div>
                    </div>
                    {selectedCampaign && (
                      <div
                        className={clsx({
                          'gap-2 align-items-center form-check form-switch switchInput': true,
                          'pt-0': !!errors?.name,
                          'switchInputDisabled': isCampaignComplete,
                        })}
                        style={{ margin: 'auto 0 auto 10px'}}
                      >
                        <input
                          value={values.status}
                          onChange={handleChange}
                          name="status"
                          className={clsx({
                            'switchBtn form-check-input': true,
                            'switchBtn-off': !values.status,
                            'switchBtn-disabled' : isCampaignComplete,
                          })}
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={values.status}
                          disabled={isCampaignComplete}
                        />
                        <label className="form-check-label switchLabel" style={{paddingLeft: 5}}>
                          Active
                        </label>
                      </div>
                    )}
                  </div>
                  {/* Start of second row */}
                  <div className="row">
                    <div className="col-md-3">
                      <span className="input-label">Start Date</span>
                      <span className="input-required">*</span>
                      <div className="d-flex flex-column" style={{ paddingTop: 5 }}>
                        <DatePicker
                            className={'input p-2 rounded'}
                            style={{ height: '10px', width: '100%', paddingTop: 10}}
                            isClearable
                            closeOnScroll={true}
                            selected={startDate ? startDate : null}
                            customInput={
                              <MaskedInput
                                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                  guide={false}
                                  pipe={dateFormat}
                                  keepCharPositions= {true}
                              />}
                            onChange={handleStartDate}
                            dateFormat={dateFormat.toLowerCase().replace('mm', "MM")}
                            placeholderText={dateFormat}
                            minDate={new Date('01/01/1900')}
                            maxDate={new Date('01/01/2200')}
                            todayButton="Today"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showPopperArrow={false}
                            fixedHeight
                            disabled={!isCampaignActive}
                        />
                        {errors.startDate &&
                            <p className="is-danger capitalize">{capitalize(errors.startDate.replace(/([A-Z])/g, ' $1').trim())}</p>
                        }
                      </div>
                    </div>
                    <div className="col-md-3">
                      <span className="input-label">End Date</span>
                      <span className="input-required">*</span>
                      <div className="d-flex flex-column" style={{ paddingTop: 5 }}>
                        <DatePicker
                            className={'input p-2 rounded'}
                            isClearable
                            closeOnScroll={true}
                            selected={endDate ? endDate : null}
                            customInput={
                              <MaskedInput
                                  pipe={dateFormat}
                                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                  keepCharPositions= {true}
                                  guide = {false  }
                              />}

                            onChange={handleEndDate}
                            dateFormat={dateFormat.toLowerCase().replace('mm', "MM")}
                            placeholderText={dateFormat}
                            minDate={new Date('01/01/1900')}
                            maxDate={new Date('01/01/2200')}
                            todayButton="Today"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showPopperArrow={false}
                            fixedHeight
                            disabled={!isCampaignActive}
                        />
                        {errors.endDate &&
                            <p className="is-danger capitalize">{capitalize(errors.endDate.replace(/([A-Z])/g, ' $1').trim())}</p>
                        }
                      </div>
                    </div>
                    <div className="col-md-3">
                      <span className="input-label">Due Date</span>
                      <span className="input-required">*</span>
                      <div className="d-flex flex-column" style={{ paddingTop: 5 }}>
                        <DatePicker
                            className={'input p-2 rounded'}
                            isClearable
                            closeOnScroll={true}
                            selected={dueDate}
                            customInput={
                              <MaskedInput
                                  pipe={dateFormat}
                                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                  keepCharPositions= {true}
                                  guide = {false  }
                              />}
                            onChange={handleDueDate}
                            dateFormat={dateFormat.toLowerCase().replace('mm', "MM")}
                            placeholderText={isCampaignComplete && !dueDate ? '' : dateFormat}
                            minDate={new Date('01/01/1900')}
                            maxDate={new Date('01/01/2200')}
                            todayButton="Today"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showPopperArrow={false}
                            fixedHeight
                            disabled={!isCampaignActive}
                        />
                        {errors.dueDate &&
                            <p className="is-danger capitalize">{capitalize(errors.dueDate.replace(/([A-Z])/g, ' $1').trim())}</p>
                        }
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                          className="d-flex flex-column w-100 gap-5"
                          key={audienceList}
                      >
                    <span className="input-label">
                      Audience
                      <span className="input-required">*</span>
                    </span>
                        <SelectComponent
                            text={fields.audience}
                            list={audienceList}
                            onSelect={handleSelectAudience}
                            width="100%"
                            height="250px"
                            withSearch
                            disabled={campaignInFlight || !isCampaignActive}
                        />
                        {errors.audience && (
                            <p className="is-danger capitalize">
                              {errors.audience}
                            </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100 mt-2 d-flex">
                    <span className="border-bottom w-100 text-18 text-black">
                      Targets
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <InputLabel
                        errors={errors}
                        errorName="budget"
                        maxLength={11}
                        type="text"
                        label="Budget"
                        placeholder="Enter a budget for this campaign"
                        name="budget"
                        value={formatDecimal(values.budget)}
                        onChange={handleChange}
                        disabled={!isCampaignActive}
                    />
                    <InputLabel
                        errors={errors}
                        errorName="rating"
                        maxLength={11}
                        type="text"
                        label="Rating"
                        placeholder={
                          !isCampaignActive && !values.rating ?
                            "" :
                            "Enter the target ratings for this campaign" 
                        }
                        name="rating"
                        value={formatDecimal(values.rating)}
                        onChange={handleChange}
                        disabled={!isCampaignActive}
                    />
                    <InputLabel
                        errors={errors}
                        errorName="reach"
                        maxLength={5}
                        label="Reach (%)"
                        placeholder="Enter the target reach for this campaign"
                        name="reach"
                        value={values.reach}
                        onChange={handleReachInput}
                        disabled={!isCampaignActive}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <InputLabel
                        errors={errors}
                        maxLength={4}
                        label="Cost per point (CPP)"
                        placeholder={
                          !isCampaignActive && !values.costPerPoint ?
                            "" :
                            "Enter the target CPP for this campaign" 
                        }
                        name="costPerPoint"
                        value={formatDecimal(values.costPerPoint)}
                        onChange={handleChange}
                        disabled={!isCampaignActive}
                    />
                    <InputLabel
                        errors={errors}
                        maxLength={4}
                        label="Frequency"
                        placeholder={
                          !isCampaignActive && !values.frequency ?
                            "" :
                            "Enter the target frequency for this campaign" 
                        }
                        name="frequency"
                        value={values.frequency}
                        onChange={handleDecimal}
                        disabled={!isCampaignActive}
                    />
                  </div>
                  <div className="d-flex gap-3 mt-2 align-items-center">
                    <span>Op2mise for:</span>
                    <Switch
                      options={switchOpt}
                      onClick={handleSwitch}
                      disabled={!isCampaignActive}
                    />
                  </div>
                  {/* Start of Limits */}
                  <div className="w-100 mt-2 d-flex">
                    <span className="border-bottom w-100 text-18 text-black">
                      Limits
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <InputLabel
                        errors={errors}
                        maxLength={4}
                        type="text"
                        label="Maximum number of spots per program"
                        placeholder={
                          !isCampaignActive && !values.maxSpotsPerProgram ?
                            "" :
                            "Enter a maximum number of spots per program" 
                        }
                        name="maxSpotsPerProgram"
                        value={formatDecimal(values.maxSpotsPerProgram)}
                        onChange={handleChange}
                        disabled={!isCampaignActive}
                    />
                    <InputLabel
                        errors={errors}
                        maxLength={4}
                        label="Maximum number of spots per program per week"
                        placeholder={
                          !isCampaignActive && !values.maxSpotsPerProgramPerWeek ?
                            "" :
                            "Enter a maximum number of spots per program per week" 
                        }
                        name="maxSpotsPerProgramPerWeek"
                        value={formatDecimal(values.maxSpotsPerProgramPerWeek)}
                        onChange={handleChange}
                        disabled={!isCampaignActive}
                    />
                  </div>
                </div>
              </div>
              {/* Button Container */}
              <div className="form-btn-container align-items-center d-flex gap-4 mt-5 justify-content-end" style={{ width: '95%' }}>
                <div>
                  <Button
                    text="Close"
                    onClick={() => handleClose(false)}
                    defaultBtn
                  />
                </div>
                <div>
                  <Button
                    text="Save"
                    type="submit"
                    onClick={() => {handleOnValidate(false)}}
                    disabled={isCampaignComplete || isCampaignSaving}
                  />
                </div>
              </div>

              <ConfirmLeaveModal
                show={(showPrompt || showConfirmLeaveModal) && (!showCampaignSavedModal && !showCampaignNotificationModal)}
                onHide={handleOnHide}
                onSave={() => handleOnSaveAndProceed(handleOnValidate)}
                onDiscard={handleOnDiscard}
              />
            </Form>;
      }}
    </Formik>
  </div>;
})

export default CampaignHeaderForm;
