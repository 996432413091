import React, {
  useState,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import DaySpendFields from './DaySpendFields';
import Button from 'shared/components/button/Button';
import Dialog from 'components/Dialogs/Dialog';
import ConfirmLeaveModal from 'shared/components/confirm-leave-modal/ConfirmLeaveModal';
import useStore from '../hooks/useCampaignStore';
import { defaultDayWeekRows, defaultDayPartRows, defaultAvailsExcludes, ExcludeFieldType, ExcludesDropdownLists } from '../CampaignConstants';
import { useCallbackPrompt } from 'components/_reusable/blocker/UsePromptComponent.ts';
import { saveSpendByDayAndWeek } from '../requests';
import ExcludeAvailsFields from './ExcludeAvailsFields';

const DaySpendForm = forwardRef(({}, ref) => {
  // Campaign Store
  const {
    isCampaignActive,
    setShowCampaignForms,
    isFormTouched,
    setIsFormTouched,
    selectedCampaign,
    setIsFormSaved,
    setChangeFormTab,
    setNewCampaign,
    newCampaign,
    dayAndWeekDistributionResults,
    excludedAvailResults,
    setExcludedAvailResults,
    setDayAndWeekDistributionResults,
    campaignsCreateFields,
    setActiveFormId,
  } = useStore((state) => state);

  // State Declarations
  const [dayWeekList, setDayWeekList] = useState([]);
  const [dayPartList, setDayPartList] = useState([]);
  const [dayWeekRows, setDayWeekRows] = useState(defaultDayWeekRows);
  const [dayPartRows, setDayPartRows] = useState(defaultDayPartRows);
  const [formErrors, setFormErrors] = useState({});
  const [dayWeekErrors, setDayWeekErrors] = useState({});
  const [dayPartErrors, setDayPartErrors] = useState({});
  const [showSaveDialog, setShowSaveDialog] = useState(false);

  const defaultAvailsExcludesValue = [
    { id: 1, type: ExcludeFieldType.RATINGS , selectedExclude: defaultAvailsExcludes[0].id, value: null },
    { id: 2, type: ExcludeFieldType.RATINGS , selectedExclude: defaultAvailsExcludes[1].id, value: null },
    { id: 3, type: ExcludeFieldType.UNIT_PRICE , selectedExclude: defaultAvailsExcludes[2].id, value: null },
    { id: 4, type: ExcludeFieldType.UNIT_PRICE , selectedExclude: defaultAvailsExcludes[3].id, value: null },
  ]
  
  const [availsExcludes, setAvailsExcludes] = useState(defaultAvailsExcludesValue);

  // Dirty Forms
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    handleHideNavigation,
  ] = useCallbackPrompt(isFormTouched);
  const [
    shouldExecuteSaveAndProceed,
    setShouldExecuteSaveAndProceed,
  ] = useState([false, () => {}]);
  const [proceedToNavigation, setProceedToNavigation] = useState(false);

  useImperativeHandle(ref, () => ({
    submitForm(callbackFunction) {
      setShouldExecuteSaveAndProceed([true, callbackFunction]);
    },
  }));

  const validateDayWeekRows = useCallback(() => {
    const dayWeekErrors = {};

    const sortedRows = [...dayWeekRows].sort(
      (a, b) => parseInt(a.startDayId) - parseInt(b.startDayId)
    );

    // Check for overlaps and assign errors
    sortedRows.forEach((currentRow, index) => {
      // Skip rows without start/end days
      if (!currentRow.startDayId || !currentRow.endDayId) return;

      sortedRows.forEach((compareRow, compareIndex) => {
        // Avoid comparing a row with itself
        if (index === compareIndex) return;

        const hasNullEntries = [
          compareRow.startDayId,
          compareRow.endDayId,
          currentRow.startDayId,
          currentRow.endDayId,
        ].every((value) => value != null);

        // Check for overlap
        const overlap =
          hasNullEntries &&
          compareRow.startDayId <= currentRow.endDayId &&
          compareRow.endDayId >= currentRow.startDayId;

        // If overlap exists, mark both rows with an error
        if (overlap) {
          dayWeekErrors[currentRow.id] = {
            startDay: 'Overlapping days are not allowed',
          };
          dayWeekErrors[compareRow.id] = {
            startDay: 'Overlapping days are not allowed',
          };
        }
      });
    });

    // Check if row has allocation but no defined dates
    dayWeekRows.forEach((row) => {
      if (
        !dayWeekErrors[row.id] &&
        (!row.startDayId || !row.endDayId) &&
        (row.startDayId || row.endDayId || row.allocation > 0)
      ) {
        dayWeekErrors[row.id] = {
          startDay: !row.startDayId
            ? 'Start Day is required for this field'
            : undefined,
          endDay: !row.endDayId
            ? 'End Day is required for this field'
            : undefined,
        };
      }
    });

    return dayWeekErrors;
  }, [dayWeekRows]);

  const validateDayPartRows = useCallback(() => {
    const errors = {};
    const seenDayparts = new Map(); // To track the first occurrence of each daypart

    if (!dayPartRows.length) return errors;

    dayPartRows.forEach((row) => {
      if (row.daypartId) {
        // Check if daypart has already been encountered
        if (seenDayparts.has(row.daypartId)) {
          const firstId = seenDayparts.get(row.daypartId);
          errors[firstId] = { daypart: 'Duplicate dayparts are not allowed' };
          errors[row.id] = { daypart: 'Duplicate dayparts are not allowed' };
        } else {
          // Store the first occurrence of the daypart
          seenDayparts.set(row.daypartId, row.id);
        }
      }
    });

    // Check if row has allocation but no defined dates
    dayPartRows.forEach((row) => {
      if (!errors[row.id] && !row.daypartId && row.allocation > 0) {
        errors[row.id] = { daypart: 'Daypart is required for this field' };
      }
    });

    return errors;
  }, [dayPartRows])

  const handleOnValidate = useCallback(() => {
    const dayWeekRowErrors = validateDayWeekRows();
    const dayPartRowErrors = validateDayPartRows();
    const formHasErrors =
      Object.keys(dayWeekRowErrors).length ||
      Object.keys(dayPartRowErrors).length;

    setDayWeekErrors(dayWeekRowErrors);
    setDayPartErrors(dayPartRowErrors);

    return Boolean(formHasErrors);
  }, [validateDayWeekRows, validateDayPartRows]);

  // Dirty Forms
  const handleNavigate = (proceed) => {
    if (!showConfirmLeaveModal && !shouldExecuteSaveAndProceed[0])
      confirmNavigation();
    else {
      if (shouldExecuteSaveAndProceed[0]) {
        shouldExecuteSaveAndProceed[1]();
        setShouldExecuteSaveAndProceed([false, () => {}]);
      } else {
        setShowConfirmLeaveModal(false);
        if (proceed) handleCloseForm(true);
      }
    }
  };

  // Form Handlers
  const handleCloseForm = (proceedFromNavigation) => {
    if (isFormTouched && !proceedFromNavigation) {
      setShowConfirmLeaveModal(true);
    } else {
      setShowCampaignForms(false);
      setShowSaveDialog(false);
      setNewCampaign(false);
      if (showConfirmLeaveModal) setShowConfirmLeaveModal(false);
    }
  };

  const handleSubmitForm = useCallback(async () => {
    
    const formHasErrors = handleOnValidate();

    if (formHasErrors) {
      setChangeFormTab(false);
      if (showConfirmLeaveModal) setShowConfirmLeaveModal(false);
      else cancelNavigation();
    }

    if (!formHasErrors) {

      const excludes = availsExcludes.map((row) => ({
        id: row.selectedExclude,
        type: row.type,
        value: parseFloat(row.value) || null, // parse to float before sending to API
      }));
      
      const payload = {
        dayOfWeeks: dayWeekRows
          .filter((row) => row.startDayId && row.endDayId)
          .map((row) => ({
            startDayId: row.startDayId,
            endDayId: row.endDayId,
            allocation: parseFloat(row.allocation) || 0,
            exclude: row.exclude,
          })),
        dayparts: dayPartRows
          .filter((row) => row.daypartId)
          .map((row) => ({
            daypartId: row.daypartId,
            allocation: parseFloat(row.allocation) || 0,
            exclude: row.exclude,
          })),
        excludedAvails: excludes
      };

      try {
        await saveSpendByDayAndWeek(
          selectedCampaign.id,
          JSON.stringify(payload)
        );
        setDayAndWeekDistributionResults({
          ...dayAndWeekDistributionResults,
          dayOfWeekDistributionResults: payload.dayOfWeeks,
          daypartDistributionResults: payload.dayparts,
        });

        setExcludedAvailResults(payload.excludedAvails)

        setIsFormSaved(true);
        setShowSaveDialog(true);
        setChangeFormTab(false);
        setIsFormTouched(false);
        if (proceedToNavigation) setNewCampaign(false);
      } catch (error) {
        console.error(error);
        handleNavigate(false);
      }
    }
  }, [availsExcludes, handleOnValidate, showConfirmLeaveModal]);

  // Dialog Handlers
  const handleCloseSaveDialog = () => {
    if (newCampaign && !proceedToNavigation) setActiveFormId('notifications');
    setShowSaveDialog(false);
    if (proceedToNavigation) handleNavigate(true);
  };

  const handleOnHide = () => {
    if (showConfirmLeaveModal) setShowConfirmLeaveModal(false);
    else cancelNavigation();
  };

  const handleOnDiscard = () => {
    if (showConfirmLeaveModal) handleCloseForm(true);
    else {
      setNewCampaign(false);
      confirmNavigation();
    }
    setIsFormTouched(false);
    setProceedToNavigation(true);
  };

  const handleOnSaveAndProceed = useCallback(() => {
    handleSubmitForm(true);
    setProceedToNavigation(true);
    handleHideNavigation();
  }, [showConfirmLeaveModal, handleSubmitForm]);

  useEffect(() => {
    if (shouldExecuteSaveAndProceed[0]) handleOnSaveAndProceed();
  }, [shouldExecuteSaveAndProceed]);

  useEffect(() => {
    if (!newCampaign) {
      const {
        dayOfWeekDistributionResults,
        daypartDistributionResults,
        daysOfWeekFields,
        daypartFields,
      } = dayAndWeekDistributionResults;

      // Set Dropdown Values
      setDayWeekList(daysOfWeekFields);
      setDayPartList(daypartFields);

      
      const currentExcludedAvails = defaultAvailsExcludesValue.map((item, index) => {;
        
        return {
          ...item,
          value: excludedAvailResults[index]?.value || "",
          selectedExclude: excludedAvailResults[index]?.id ?? defaultAvailsExcludesValue[index].selectedExclude
        }
      })

      setAvailsExcludes(currentExcludedAvails)


      if (
        Array.isArray(dayOfWeekDistributionResults) &&
        dayOfWeekDistributionResults?.length
      ) {
        const newDayWeekRows = dayOfWeekDistributionResults.map(
          (item, index) => ({
            ...item,
            id: index,
          })
        );
        setDayWeekRows(newDayWeekRows);
      }
      if (
        Array.isArray(daypartDistributionResults) &&
        daypartDistributionResults?.length
      ) {
        const newDayPartRows = daypartDistributionResults.map(
          (item, index) => ({
            ...item,
            id: index,
          })
        );
        setDayPartRows(newDayPartRows);
      }
    }

    if (newCampaign) {
      // Set Dropdown Values
      if (campaignsCreateFields?.days?.length) {
        setDayWeekList(campaignsCreateFields?.days);
      }
      if (campaignsCreateFields?.dayparts?.length) {
        setDayPartList(campaignsCreateFields?.dayparts);
      }
    }
  }, []);


  return (
    <div className="w-100 d-flex flex-column gap-2 default-text form">
      <div className="min-max-h-20 d-flex flex-column gap-4 campagin-form customScroll">
        <DaySpendFields
          dayPartErrors={dayPartErrors}
          dayPartList={dayPartList}
          dayPartRows={dayPartRows}
          dayWeekErrors={dayWeekErrors}
          dayWeekList={dayWeekList}
          dayWeekRows={dayWeekRows}
          formErrors={formErrors}
          isFormTouched={isFormTouched}
          setIsFormTouched={setIsFormTouched}
          setDayPartRows={setDayPartRows}
          setDayWeekRows={setDayWeekRows}
          setDayPartErrors={setDayPartErrors}
          setDayWeekErrors={setDayWeekErrors}
        />

        <ExcludeAvailsFields
          availsExcludes={availsExcludes}
          setAvailsExcludes={setAvailsExcludes}
          isFormTouched={isFormTouched}
          setIsFormTouched={setIsFormTouched}
        />
      </div>

      <div className="form-btn-container align-items-center d-flex gap-4 mt-5 justify-content-end" style={{ width: '95%' }}>
        <div>
          <Button
            text="Close"
            onClick={() => handleCloseForm(false)}
            defaultBtn
          />
        </div>
        <div>
          <Button
            text="Save"
            type="submit"
            onClick={handleSubmitForm}
            disabled={!isCampaignActive}
          />
        </div>
      </div>

      <Dialog onClose={handleCloseSaveDialog} show={showSaveDialog}>
        Campaign {selectedCampaign?.name} has been updated.
      </Dialog>

      <ConfirmLeaveModal
        show={(showPrompt || showConfirmLeaveModal) && !showSaveDialog}
        onHide={handleOnHide}
        onSave={handleOnSaveAndProceed}
        onDiscard={handleOnDiscard}
      />
    </div>
  );
});

export default DaySpendForm;
