import React from 'react'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import './OpButton.css';

export const OpButton = ({
    onClick,
    label,
    loading,
    icon,
    iconPosition = 'right', //Left || Right
    style,
    disabled = false,
    allowTextWrap = false,
    invertIconColor = false,
}) => {

    return (
        <ButtonComponent
            onClick={onClick}
            disabled={disabled || loading}
            iconPosition={iconPosition}
            cssClass='e-custom-button'
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                flexDirection: iconPosition === 'right' ? 'row' : 'row-reverse',
                gap: '10px',
            }}
        >
            <span style={{ whiteSpace: allowTextWrap ? 'normal' : 'nowrap' }}>
                {label}
            </span>
            {loading && (
                <i
                className="fas fa-circle-notch fa-spin"
                style={{ marginLeft: '5px' }}
                />
            )}
            {
                icon && !loading && <img
                    src={icon}
                    alt={label}
                    className='icon'
                    style={{
                        color: '#FFFFFF',
                        width: '14px',
                        height: '14px',
                        filter: invertIconColor ? 'invert(100%) brightness(200%) contrast(200%)' : 'none', //invert colors from black to white
                    }}
                />
            }
        </ButtonComponent >
    )
}