/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
import React from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { LicenseManager } from 'ag-grid-enterprise';
// eslint-disable-next-line import/no-duplicates
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
// eslint-disable-next-line import/no-duplicates
import config from './op-datagrid.config.json';
import { BaseGrid, DualGrid, OverviewGrid } from './components';
import { applyCapitalization, applyStyleOnChildHeaderCell } from './utils';
import { Modes } from './types';
import './OpDataGrid.css';
/* eslint-disable */

const { Common, Constants, DefaultBaseGridProps, DefaultDualGridProps, DefaultOverviewGridProps } = config;

LicenseManager.setLicenseKey(Common.apiKey);
ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule, RowGroupingModule]);

export const OpDataGrid = (props) => {
  /** (see link for data-grid options reference): https://ag-grid.com/react-data-grid/grid-options/ */

  const initialState = React.useMemo(() => {
    return {
      basicGridState: {
        ...DefaultBaseGridProps,
        ...props,
      },
      dualGridState: {
        ...DefaultDualGridProps,
        leftGrid: {
          ...DefaultBaseGridProps,
          ...props.dualGridProps?.leftGrid
        },
        rightGrid: {
          ...DefaultBaseGridProps,
          ...props.dualGridProps?.rightGrid
        },
        actions: props.actions ?? DefaultDualGridProps.actions,
        alignedGrid: props.alignedGrid ?? DefaultDualGridProps.alignedGrid,
      },
      overviewGridState: {
        ...DefaultOverviewGridProps,
        baseGrid: { ...DefaultBaseGridProps },
        overviewGrid: { ...DefaultBaseGridProps },
        ...props.overviewGridProps,
      },
      mode: props.mode ?? Common.mode,
      theme: props.theme ?? Common.theme,
    }
  }, [props]);

  // Refs
  const mainWrapperRef = React.useRef(null);
  const overviewGridRef = React.useRef(null);
  const baseGridRef = React.useRef(null);
  const overviewGridWrapperRef = React.useRef(null);

  const {
    basicGridState,
    dualGridState,
    overviewGridState,
    mode,
    theme
  } = initialState;

  const isBasicMode = React.useMemo(() => mode === Modes.BASIC, [mode]);
  const isDualMode = React.useMemo(() => mode === Modes.DUAL, [mode]);
  const isOverviewMode = React.useMemo(() => mode === Modes.OVERVIEW, [mode]);
  const showDataGridOverview = isDualMode; // SUBJECT FOR DEPRECATION

  const baseGridStateByMode = React.useMemo(() => {
    if (mode === Modes.BASIC) return { ...basicGridState };
    if (mode === Modes.OVERVIEW) return { ...initialState.overviewGridState.baseGrid };
    return { ...DefaultBaseGridProps };
  }, [props]);

  // States
  const [columnOverviewDefinition, setColumnOverviewDefinition] = React.useState(overviewGridState.overviewGrid.columns); // SUBJECT FOR REMOVAL
  const [isVerticalScroll, setIsVerticalScroll] = React.useState(false); // Used only in overview, but is being invoked in Base
  const [columnDefinition, setColumnDefinition] = React.useState(baseGridStateByMode.columns);

  const handleDefaultColDefs = React.useMemo(() => {
    /**
     * Dynamically sets default column definitions for ag-Grid.
     * Applies the given default settings to the `defaultColDef` property of the provided `gridOptions` object.
     */
    const baseGridState = baseGridStateByMode;

    return {
      editable: baseGridState.editable,
      filter: baseGridState.filter,
      lockPinned: baseGridState.lockPinned,
      resizable: baseGridState.resizable,
      sortable: baseGridState.sortable,
      minWidth: Constants.ColDef.minWidth,
    }
  }, [baseGridStateByMode]);

  const applyCellClassMethod = React.useCallback((params) => {
    /**
     * This function determines the CSS class for the cell in an ag-grid column based on the row's selection status.
     * If the row is selected, the cell background color is set to the specified selected row background color (--ag-selected-row-background-color).
     * If the row is not selected, the cell background color alternates between two designated colors (--op2mise-color-ash-gray) and --op2mise-color-light-gray.
     * Prevents overlapping of styles betweeen the --ag-selected-row-background-color and the 'booked' auto cell styling
     * */
    const selectedRowBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--ag-selected-row-background-color');
    const bookedColumnStyle = (backgroundColor) => {
      return { 'textAlign': 'right', 'backgroundColor': backgroundColor, 'boxShadow': '3px 0px 4px rgba(0, 0, 0, 0.10) inset' };
    }
    if (params) {
      if (params.node.isSelected()) {
        return { ...bookedColumnStyle(selectedRowBackgroundColor) }
      } else {
        return params.rowIndex % 2 !== 0 ? bookedColumnStyle('var(--op2mise-color-ash-gray)') : bookedColumnStyle('var(--op2mise-color-light-gray)');
      }
    }
  }, []);

  const handleCapitalization = React.useCallback((columnInstance) => applyCapitalization({ columnInstance }), []);

  const handleChildColumnHeaders = React.useCallback((columnInstance) => applyStyleOnChildHeaderCell({ columnInstance }), []);

  return (
    <div ref={mainWrapperRef}>
      <div>
        {/* `showDataGridOverview` PROP IS SUBJECT FOR DEPRECATION, TO BE REPLACED BY MODE */}
        {((isOverviewMode)) && (
          <OverviewGrid {...{
            columnDefinition,
            columnOverviewDefinition,
            datagridOverviewProperties: overviewGridState.overviewGrid, // SUBJECT FOR DEPRECATION
            isVerticalScroll,
            // loading, // Handle loading inside component
            baseGridRef,
            mainWrapperRef,
            overviewGridRef,
            overviewGridWrapperRef,
            setColumnOverviewDefinition,
            showDataGridOverview,
            applyCellClassMethod, // SUBJECT FOR PROP REMOVAL
            handleCapitalization, // SUBJECT FOR PROP REMOVAL
            handleChildColumnHeaders, // SUBJECT FOR PROP REMOVAL
          }} />
        )}
        {(isBasicMode || isOverviewMode) && (
          <BaseGrid {...{
            ...baseGridStateByMode,
            columnDefinition,
            defaultColumnProperties: handleDefaultColDefs,
            baseGridRef,
            overviewGridRef,
            setColumnDefinition,
            setIsVerticalScroll,
            theme,
          }} />
        )}
      </div>
      <div>
        {isDualMode && (
          <DualGrid {...{ ...dualGridState, theme }} />
        )}
      </div>
    </div>
  );
};

/**
 * GENERAL NOTES:
 *  1. Utilize Loader insinde Overview Component
 *  2. Refactor overview component
 *  3. Remove props/states subject for Deprecation and/or removal
 */
