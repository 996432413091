import React from "react";
import { Modal } from "react-bootstrap";
import {
    helpIcon,
    pencilGreenIcon,
    plusCircleAltIcon,
    trashCanIcon
} from "assets";
import { useCallbackPrompt } from "components/_reusable/blocker/UsePromptComponent.ts";
import {
    deleteEmailTemplate,
    getEmailTemplates,
    saveEmailTemplate,
    sendEmailToStations,
    updateEmailTemplate
} from "components/campaigns/requests";
import Button from "shared/components/button/Button";
import ConfirmLeaveModal from "shared/components/confirm-leave-modal/ConfirmLeaveModal";
import Input from "shared/components/input/Input";
import Select from "shared/components/select/Select";
import Switch from "shared/components/switch/Switch";
import SendTestEmailForm from "./SendTestEmailForm";
import TemplateNameForm from "./TemplateNameForm";
import "../../campaigns.css";

const TextArea = ({
    activeSwitch,
    asteriskOn,
    disabled,
    errorMessage,
    height,
    label,
    name,
    onChange,
    onSwitch,
    showIncludeSwitch,
    style,
    value,
}) => {
    return (
        <>
            <div className={'d-flex position-relative'}>
                {label && (
                    <div className={'text-area-label'}>
                        {label}
                        {asteriskOn && <span style={{ color: '#FF0000' }}>*</span>}
                    </div>
                )}

                {showIncludeSwitch && (
                    <div style={{ marginTop: '-3px', position: 'absolute', right: '0' }}>
                        <Switch active={activeSwitch} label={'Include'} onSwitch={onSwitch} />
                    </div>
                )}
            </div>

            <textarea
                className={'text-area'}
                disabled={disabled}
                name={name}
                onChange={onChange}
                style={{ height, ...style }}
                value={value}
            ></textarea>

            {errorMessage && (
                <small className={'compose-email-form-error-message'}>{errorMessage}</small>
            )}
        </>
    );
};

const HelpTooltip = ({ onHide }) => {
    return (
        <Modal centered={true} dialogClassName={'help-tooltip-modal'} onHide={onHide} show={true}>
            <div className={'help-tooltip-wrapper'}>
                <b>Below variables can be used on any editable section of the template.</b>
                <br /><br />
                <p style={{ lineHeight: '8px' }}><b>{`{user_organization_name}`}</b> Organization name of the current logged in user.</p>
                <p style={{ lineHeight: '8px' }}><b>{`{campaign_name}`}</b> Campaign name where the notification will be sent.</p>
                <p style={{ lineHeight: '8px' }}><b>{`{station_contact_fname}`}</b> Selected station(s) contact's first name.</p>
                <p style={{ lineHeight: '8px' }}><b>{`{campaign_uid}`}</b> Campaign ID where the notification will be sent.</p>
                <p style={{ lineHeight: '8px' }}><b>{`{campaign_period}`}</b> Campaign period where the notification will be sent.</p>
                <p style={{ lineHeight: '8px' }}><b>{`{campaign_audience}`}</b> Campaign audience where the notification will be sent.</p>
                <p style={{ lineHeight: '8px' }}><b>{`{campaign_due_date}`}</b> Campaign due date where the notification will be sent.</p>
            </div>
        </Modal>
    );
};

const ComposeEmailForm = ({ onHide, selectedCampaign, selectedStations, setShowSuccessfullyNotifiedModal }) => {
    const defaultTemplate = {
        id: null,
        includeDetails: true,
        includeFooter: true,
        lowerBody: '',
        name: '',
        subject: '',
        upperBody: '',
    };
    const [emailTemplates, setEmailTemplates] = React.useState([]);
    const emailTemplatesLookup = emailTemplates?.map((o) => ({ id: o.id, name: o.name }));
    const iconStyles = {
        cursor: 'pointer',
        height: '20px',
        marginLeft: '10px',
        marginTop: '32px',
        width: '20px',
    };
    const [emailTemplateErrors, setEmailTemplateErrors] = React.useState({});
    const [isNotifyingStations, setIsNotifyingStations] = React.useState(false);
    const [isSavingTemplate, setIsSavingTemplate] = React.useState(false);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = React.useState({});
    const [showHelpTooltip, setShowHelpTooltip] = React.useState(false);
    const [isSaveAsNew, setIsSaveAsNew] = React.useState(false);
    const [showSendTestEmailForm, setShowSendTestEmailForm] = React.useState(false);
    const [showTemplateNameForm, setShowTemplateNameForm] = React.useState('');

    // Dirty forms
      const [isFormDirty, setIsFormDirty] = React.useState(false);
      const [nextAction, setNextAction] = React.useState({});
      const [showConfirmLeaveModal, setShowConfirmLeaveModal] = React.useState(false);
      const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isFormDirty
      );

    const cleanTemplate = (selectedEmailTemplate) => {
        return {
            ...selectedEmailTemplate,
            upperBody: selectedEmailTemplate.upperBody.trim(),
            lowerBody: selectedEmailTemplate.lowerBody.trim(),
        };
    };

    const clearErrorMessages = () => {
        setEmailTemplateErrors({});
    };

    const validateEmailTemplateForm = () => {
        if (!selectedEmailTemplate.name || !selectedEmailTemplate.subject || !selectedEmailTemplate.upperBody) {
            setEmailTemplateErrors({
                name: !selectedEmailTemplate.name ? 'Template is required' : '',
                subject: !selectedEmailTemplate.subject ? 'Subject is required' : '',
                upperBody: !selectedEmailTemplate.upperBody ? 'Body is required' : '',
            });

            return false;
        }

        return true;
    };

    const handleDeleteEmailTemplate = async () => {
        if (!selectedEmailTemplate.defaultTemplate) {
            await deleteEmailTemplate(selectedEmailTemplate.id);
            clearErrorMessages();
            await handleGetEmailTemplates();
            // Select first record when deleting a template
            const template = emailTemplates[0];
            setSelectedEmailTemplate(template);
        }
    };

    const handleGetEmailTemplates = async (data) => {
        var res = await getEmailTemplates();
        setEmailTemplates(res.emailTemplates);

        // Update the current selected template when edited
        if ((data || selectedEmailTemplate).name) {
            const matchingTemplates = res.emailTemplates.filter(o => o.name === (data || selectedEmailTemplate).name);
    
            // Get the latest updated record if it has a duplicated name
            if (matchingTemplates.length > 0) {
                const latestTemplate = matchingTemplates.reduce((latest, current) => 
                    new Date(current.updatedDate) > new Date(latest.updatedDate) ? current : latest
                );
                setSelectedEmailTemplate(latestTemplate);
            }
        } else {
            setSelectedEmailTemplate(res.emailTemplates[0]);
        }
    };

    const handleNotifyStations = async (proceedActionFromDirtyForms) => {
        if (isFormDirty && !proceedActionFromDirtyForms) {
            setNextAction({ action: 'notify-stations' });
            setShowConfirmLeaveModal(true);
        } else {
            if (validateEmailTemplateForm()) {
                setIsNotifyingStations(true);
                await sendEmailToStations(
                    selectedEmailTemplate.id,
                    { campaignId: selectedCampaign.id, stationIds: selectedStations },
                );
                clearErrorMessages();
                if (showPrompt) confirmNavigation();
                setIsFormDirty(false);
                setIsNotifyingStations(false);
                setShowSuccessfullyNotifiedModal(true);
                onHide(false);
            }
        }
    };

    const handleOnChangeSelectedEmailTemplate = (event) => {
        setIsFormDirty(true);
        setSelectedEmailTemplate((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleOnCloseEmailTemplateForm = (proceedActionFromDirtyForms) => {
        if (isFormDirty && !proceedActionFromDirtyForms) {
            setNextAction({ action: 'close-email-template-form' });
            setShowConfirmLeaveModal(true);
        } else {
            onHide(false);
        }
    };

    const handleOpenCreateTemplateModal = () => {
        setShowTemplateNameForm('Create');
    };

    const handleOpenEditTemplateModal = () => {
        if (!selectedEmailTemplate?.defaultTemplate) {
            setShowTemplateNameForm('Edit');
        }
    };

    const handleOpenTestEmailModal = (proceedActionFromDirtyForms) => {
        if (isFormDirty && !proceedActionFromDirtyForms) {
            setNextAction({ action: 'open-test-email-modal' });
            setShowConfirmLeaveModal(true);
        } else {
            if (validateEmailTemplateForm()) {
                clearErrorMessages();
                setShowSendTestEmailForm(true);
            }
        }
    };

    const handleOnSelectTemplate = (data, proceedActionFromDirtyForms) => {
        if (isFormDirty && !proceedActionFromDirtyForms) {
            setNextAction({ action: 'select-template', data });
            setShowConfirmLeaveModal(true);
        } else {
            const template = emailTemplates?.find(o => o.id === data.id && o.name === data.name);
            clearErrorMessages();
            setSelectedEmailTemplate(template);
        }
    };

    const handleOnSwitchSelectedEmailTemplate = (name) => {
        setIsFormDirty(true);
        setSelectedEmailTemplate((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
        
    };

    const handleSaveAsNew = () => {
        setIsSaveAsNew(true);
        setShowTemplateNameForm('Create');
    };

    // parameter:data will be provided if handleSaveAsNew is executed
    const handleSaveTemplate = async (data) => {
        if (validateEmailTemplateForm()) {
            setIsSavingTemplate(true);
            await saveEmailTemplate(cleanTemplate(data || selectedEmailTemplate));
            clearErrorMessages();
            if (showPrompt) confirmNavigation();
            setIsSaveAsNew(false);
            setIsFormDirty(false);
            await handleGetEmailTemplates(data);
            setIsSavingTemplate(false);
        }
    };

    const handleSaveTemplateName = async (data, proceedActionFromDirtyForms) => {
        if (isSaveAsNew) {
            const newTemplate = { ...selectedEmailTemplate, name: data.name, id: null };
            await handleSaveTemplate(newTemplate);
            setShowTemplateNameForm('');
        } else {
            if (isFormDirty && !proceedActionFromDirtyForms) {
                setNextAction({ action: 'create-new-template', data });
                setShowConfirmLeaveModal(true);
            } else {
                const newTemplate = { ...defaultTemplate, name: data.name };
                setEmailTemplates((prevState) => ([...prevState, newTemplate]));
                setIsFormDirty(true);
                setSelectedEmailTemplate(newTemplate);
                setShowTemplateNameForm('');
            }
        }
    };

    const handleUpdateTemplate = async () => {
        if (validateEmailTemplateForm()) {
            setIsSavingTemplate(true);
            await updateEmailTemplate(selectedEmailTemplate.id, cleanTemplate(selectedEmailTemplate));
            clearErrorMessages();
            if (showPrompt) confirmNavigation();
            setIsFormDirty(false);
            await handleGetEmailTemplates();
            setIsSavingTemplate(false);
        }
    };

    const handleUpdateTemplateName = (data) => {
        setEmailTemplates((prevState) => {
            var result = prevState.map((o) => (
                o.id === data.id
                ? { ...o, name: data.name }
                : o
            ));

            var template = result.find(o => o.id === data.id && o.name === data.name);
            setSelectedEmailTemplate(template);

            return result;
        });
        setIsFormDirty(true);
        setShowTemplateNameForm('');
    };

    // Dirty forms

    // If the user is navigating inside the page this will execute the action that was prevented to execute from the dirty forms.
    const handleContinueAction = () => {
        switch(nextAction.action) {
            case 'close-email-template-form':
                    handleOnCloseEmailTemplateForm(true);
                break;
            case 'create-new-template':
                    clearErrorMessages();
                    handleSaveTemplateName(nextAction.data, true);
                break;
            case 'notify-stations':
                    handleNotifyStations(true);
                break;
            case 'open-test-email-modal':
                    handleOpenTestEmailModal(true);
                break;
            case 'select-template':
                    handleOnSelectTemplate(nextAction.data, true);
                    // Remove unsave data when discarding changes
                    setEmailTemplates((prevState) => (
                        prevState.filter(o => o.subject && o.upperBody && o.lowerBody)
                    ));
                break;
            default:
                    // do nothing
                break;
        }
    };

    const handleOnCancelPrompt = () => {
        if (showPrompt) cancelNavigation();
        else setShowConfirmLeaveModal(false);
    };

    const handleOnDiscardChanges = () => {
        if (showPrompt) confirmNavigation();
        else {
            // If creating a new template and discarding the old changes
            if (nextAction.action === 'create-new-template' && !selectedEmailTemplate.id) {
                setEmailTemplates((prevState) => (prevState.slice(0, -1)));
                setIsFormDirty(true);
            } else {
                setIsFormDirty(false);
            }
            handleContinueAction(true);
            setNextAction({});
            setShowConfirmLeaveModal(false);
        }
    };

    const handleOnSaveChanges = async () => {
        if (!selectedEmailTemplate?.id) await handleSaveTemplate();
        else await handleUpdateTemplate();

        if (showConfirmLeaveModal && validateEmailTemplateForm()) handleContinueAction();
        setShowConfirmLeaveModal(false);
        setShowTemplateNameForm('');
    };

    React.useEffect(() => {
        handleGetEmailTemplates();
    }, []);

    return (
        <Modal centered dialogClassName={'compose-email-form-modal'} show={true}>
            <div className={'compose-email-form-wrapper'}>
                <p className={'compose-email-form-title'}>Compose email message</p>

                <section className={'d-flex mb-3'}>
                    <div style={{ width: '100%' }}>
                        <Select
                            label={'Template'}
                            list={emailTemplatesLookup}
                            onSelect={(data) => handleOnSelectTemplate(data, false)}
                            text={selectedEmailTemplate?.name ?? 'Please select a Template'}
                            width={'100%'}
                        />

                        {emailTemplateErrors.name && (
                            <small className={'compose-email-form-error-message'}>{emailTemplateErrors.name}</small>
                        )}
                    </div>

                    <img
                        alt={'add-template'}
                        onClick={handleOpenCreateTemplateModal}
                        src={plusCircleAltIcon}
                        style={iconStyles} title={'Add template'}
                    />
                    <img
                        alt={'edit-template'}
                        className={selectedEmailTemplate?.defaultTemplate ? 'disabled-compose-email-form-action-button' : ''}
                        onClick={handleOpenEditTemplateModal}
                        src={pencilGreenIcon}
                        style={iconStyles}
                        title={'Edit template'}
                    />
                    <img
                        alt={'delete-template'}
                        className={selectedEmailTemplate?.defaultTemplate ? 'disabled-compose-email-form-action-button' : ''}
                        onClick={handleDeleteEmailTemplate}
                        src={trashCanIcon}
                        style={iconStyles}
                        title={'Delete template'}
                    />
                    <img alt={'help'} onClick={() => setShowHelpTooltip(true)} src={helpIcon} style={iconStyles} title={'Help'} />
                </section>

                <section>
                    <div className={'d-flex mb-3'}>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                asteriskOn={true}
                                disabled={true}
                                errorMessage={emailTemplateErrors.subject}
                                label={'Subject'}
                                onChange={handleOnChangeSelectedEmailTemplate}
                                style={{ width: '90px' }}
                                value={'Op2mise -'}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <Input
                                errorMessage={emailTemplateErrors.subject}
                                maxLength={'998'}
                                name={'subject'}
                                onChange={handleOnChangeSelectedEmailTemplate}
                                style={{ marginTop: '21px' }}
                                value={selectedEmailTemplate?.subject}
                            />
                        </div>
                    </div>

                    <div className={'mb-3'}>
                        <TextArea
                            asteriskOn={true}
                            errorMessage={emailTemplateErrors.upperBody}
                            label={'Body'}
                            name={'upperBody'}
                            onChange={handleOnChangeSelectedEmailTemplate}
                            style={{ marginBottom: '-5px' }}
                            value={selectedEmailTemplate?.upperBody}
                        />
                    </div>

                    <div className={'mb-3'}>
                        <TextArea
                            activeSwitch={selectedEmailTemplate?.includeDetails}
                            disabled={true}
                            height={'108px'}
                            label={'Campaign details'}
                            onSwitch={() => handleOnSwitchSelectedEmailTemplate('includeDetails')}
                            showIncludeSwitch={true}
                            style={{ padding: '8px 16px 8px 16px' }}
                            value={`Campaign Name: {campaign_name}\nCampaign ID: {campaign_uid}\nCampaign Period: {campaign_period}\nAudience: {campaign_audience}\nDue Date: {campaign_due_date}`}
                        />

                        <TextArea
                            name={'lowerBody'}
                            onChange={handleOnChangeSelectedEmailTemplate}
                            value={selectedEmailTemplate?.lowerBody}
                        />
                    </div>

                    <div className={'mb-3'}>
                        <TextArea
                            activeSwitch={selectedEmailTemplate?.includeFooter}
                            disabled={true}
                            height={'35px'}
                            label={'Footer'}
                            onSwitch={() => handleOnSwitchSelectedEmailTemplate('includeFooter')}
                            showIncludeSwitch={true}
                            style={{ padding: '8px', textIndent: '8px', fontStyle: 'italic' }}
                            value={'Please include this message when replying/sending avails.'}
                        />
                    </div>

                    <div className={'text-end'}>
                        <Button defaultBtn text={'Cancel'} onClick={() => handleOnCloseEmailTemplateForm(false)} />
                        <Button
                            isLoading={isSavingTemplate}
                            onClick={() => selectedEmailTemplate?.defaultTemplate
                                ? handleSaveAsNew()
                                : !selectedEmailTemplate?.id ? handleSaveTemplate() : handleUpdateTemplate()
                            }
                            style={{ marginRight: '10px' }}
                            text={selectedEmailTemplate?.defaultTemplate ? 'Save as New' : 'Save Template'}
                        />
                        <Button
                            onClick={() => handleOpenTestEmailModal(false)}
                            style={{ marginRight: '10px' }}
                            text={'Send Test  Email'}
                        />
                        <Button
                            isLoading={isNotifyingStations}
                            onClick={() => handleNotifyStations(false)}
                            text={`Notify ${selectedStations.length} Station(s)`} />
                    </div>
                </section>

                {showHelpTooltip && (
                    <HelpTooltip onHide={() => setShowHelpTooltip(false)} />
                )}

                {showTemplateNameForm === 'Create' && (
                    <TemplateNameForm
                        onHide={() => setShowTemplateNameForm(false)}
                        onSave={(data) => handleSaveTemplateName(data, false)}
                        type={'Create'}
                    />
                )}

                {showTemplateNameForm === 'Edit' && (
                    <TemplateNameForm
                        selectedEmailTemplate={selectedEmailTemplate}
                        onHide={() => setShowTemplateNameForm(false)}
                        onUpdate={handleUpdateTemplateName}
                        type={'Edit'}
                    />
                )}

                {showSendTestEmailForm && (
                    <SendTestEmailForm
                        onHide={() => setShowSendTestEmailForm(false)}
                        selectedCampaign={selectedCampaign}
                        selectedEmailTemplate={selectedEmailTemplate}
                    />
                )}
            </div>

            <ConfirmLeaveModal
                isSaving={isSavingTemplate}
                onDiscard={handleOnDiscardChanges}
                onHide={handleOnCancelPrompt}
                onSave={handleOnSaveChanges}
                show={showPrompt || showConfirmLeaveModal}
            />
        </Modal>
    );
};

export default ComposeEmailForm;