export const onModelUpdatedEvent = ({ params, gridRef, rowVoidFiller, setRowsToDisplay }) => { 

    const rowCountOnFilter = params.api.getDisplayedRowCount();

    if(!rowCountOnFilter) {
      gridRef.current.api.showNoRowsOverlay();
    }else {
      gridRef.current.api.hideOverlay();
    }
  
    if(rowVoidFiller) rowVoidFiller(params);
    setRowsToDisplay(rowCountOnFilter)

    gridRef.current.api.refreshCells({ force: true });
}