/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import { isEmpty } from 'lodash';
import InputLabel from '../../Forms/InputLabel';
// eslint-disable-next-line import/no-cycle
import {allow1Decimal, allow1Point, allow2Decimals, formatDecimal, replaceWithInt} from '../helpers/helpers';

function MarketSpendFields(props) {
  const {
    name,
    onChange,
    values,
    errors,
    index,
    setFieldValue,
    budget,
    objective,
    rating,
    isCampaignActive,
  } = props;

  const error = isEmpty(errors) ? {} : errors.markets[index];

  const handleChange = (e) => {
    // Check if input field is for allocation
    if (e.target.name.includes('allocation')) {
      // Check if campaign budget target is not null
      if (!isNaN(parseFloat(budget))) {
        const newBudgetValue = e.target.value !== ''
          ? parseFloat((budget * e.target.value) / 100)
          : '';
        setFieldValue(`markets[${index}].budgetAmount`, newBudgetValue);
      }

      // Check if campaign is op2mised by budget or rating and if
      // campaign rating target is not null
      if ((objective === 0 || objective === 2) && !isNaN(parseFloat(rating))) {
        const newRatingValue = e.target.value !== ''
          ? parseFloat((rating * e.target.value) / 100)
          : '';
        setFieldValue(`markets[${index}].targetRating`, newRatingValue);
      }
    }

    // Check if input field and campaign op2mise is budget
    // and campaign budget target is not null
    if (e.target.name.includes('budgetAmount') && !isNaN(parseFloat(budget))) {
      const newMarketAllocation = e.target.value !== ''
        ? parseFloat((e.target.value / budget) * 100).toFixed(1) || 0
        : '';

      setFieldValue(`markets[${index}].allocation`, newMarketAllocation);

      if ((objective === 0 || objective === 2) && !isNaN(parseFloat(rating))) {
        const newRatingValue = newMarketAllocation !== ''
          ? parseFloat((rating * newMarketAllocation) / 100) || 0
          : '';
        setFieldValue(`markets[${index}].targetRating`, newRatingValue);
      }
    }

    // Check if input field and campaign op2mise selection is budget or rating
    // and campaign rating target is not null
    if (
      e.target.name.includes('targetRating')
      && (objective === 0 || objective === 2)
      && !isNaN(parseFloat(rating))
    ) {
      const newMarketAllocation = e.target.value !== ''
      ? parseFloat((e.target.value / rating) * 100).toFixed(1) || 0
      : '';

      setFieldValue(`markets[${index}].allocation`, newMarketAllocation);

      if (!isNaN(parseFloat(budget))) {
        const newBudgetValue = newMarketAllocation !== ''
          ? parseFloat((budget * newMarketAllocation) / 100) || 0
          : '';
        setFieldValue(`markets[${index}].budgetAmount`, newBudgetValue);
      }
    }

    onChange(e);
  };

  const handleDecimal = (e, name) => {
    let val = e.target.value;
    const maxValue = 100;
    const decimalOnly = ['reach', 'frequency'];
    
    if (decimalOnly.includes(name))  {
        val = val.replace(/^0+/, '');
        if (val === '.0') return;
    }

    if (val.length > 1) val = val.replace(/^0+/, '');

    if (val > maxValue) return;
      val = val.replace('..', '.');

    if (val.includes('.'))  {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allow1Point(allow1Decimal(e, val));
    handleChange(e);
  };

  const handleReachInput = (e, name) => {
    let val = e.target.value;
    const maxValue = 100;
    const decimalOnly = ['reach', 'frequency'];

    if (decimalOnly.includes(name))  {
        val = val.replace(/^0+/, '');
        if (val === '.0') return;
    }

    if (val.length > 1) val = val.replace(/^0+/, '');

    if (val > maxValue) return;
    val = val.replace('..', '.');

    if (val.includes('.')) {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allow1Point(allow2Decimals(e, val));
    handleChange(e);
  };

  const handleAllocationInput = (e, name) => {
    let val = e.target.value;
    const maxValue = 100;

    if (val.length > 1) val = val.replace(/^0+/, '');

    if (val > maxValue) return;
    val = val.replace('..', '.');

    if (val.includes('.')) {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allow1Point(allow2Decimals(e, val));
    handleChange(e);
  }

    const handleTrimNumeric = (val) => {
        if (val.includes('.')) {
            // eslint-disable-next-line no-param-reassign,prefer-destructuring
            val = val.split('.')[0];
        }
        return replaceWithInt(val.replace(/\D/g, ''));
    };

  const handleNumeric = (e) => {
      e.target.value = handleTrimNumeric(e.target.value);

      handleChange(e);
  };

  return (
      <div className="d-inline-flex align-items-center r-container gap-2" style={{ width: '100%'}}>
        <div className="d-inline-flex gap-2" style={{ width: '100%'}}>
            <div className="d-inline-flex align-items-center" style={{ width: '30%'}}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis',  whiteSpace: 'nowrap' }}>{name}</span>
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel className="text-align" maxLength={5} name={`markets[${index}].allocation`} value={values[index].allocation} onChange={handleAllocationInput} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel className="text-align" maxLength={11} name={`markets[${index}].budgetAmount`} value={formatDecimal(values[index].budgetAmount)}  onChange={handleNumeric} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
              <InputLabel errors={error} errorName={'targetRating'} className="text-align" maxLength={11} name={`markets[${index}].targetRating`} value={formatDecimal(values[index].targetRating)} onChange={handleNumeric} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel errors={error} errorName={'reach'} className="text-align" maxLength={5} name={`markets[${index}].reach`} value={values[index].reach} onChange={(e) => handleReachInput(e, 'reach')} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel errors={error} errorName={'costPerPoint'} className="text-align" maxLength={4} name={`markets[${index}].costPerPoint`} value={formatDecimal(values[index].costPerPoint)} onChange={handleNumeric} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel errors={error} errorName={'frequency'} className="text-align" maxLength={4} name={`markets[${index}].frequency`} value={values[index].frequency} onChange={(e) => handleDecimal(e, 'frequency')} disabled={!isCampaignActive} />
            </div>
        </div>
      </div>
  );
}

export default MarketSpendFields;