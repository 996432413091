import React, { useEffect, useRef, useState } from 'react';
import { expandAll } from 'assets';
import './AutoGroupHeaderTemplate.css';

/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
export const AutoGroupHeaderTemplate = (params) => {
  const {
    api,
    column,
    displayName,
    enableFilterButton,
    enableMenu,
    enableSorting,
    showColumnMenu,
    showFilter,
    setSort,
  } = params;
  const [ascSort, setAscSort] = useState('');
  const [descSort, setDescSort] = useState('ag-hidden');
  const [noSort, setNoSort] = useState('ag-hidden');
  const [isFilterActive, setIsFilterActive] = useState(false);
  const menuButtonRef = useRef(null);
  const filterButtonRef = useRef(null);

  const handleExpandAll = () => {
    let isAnyExpanded = false;

    api.forEachNode((node) => {
      if (node.expanded) {
        isAnyExpanded = true;
      }
    });

    if (isAnyExpanded) {
      return api.collapseAll();
    }

    return api.expandAll();
  };

  const onMenuClicked = (e) => {
    if (!enableMenu) return undefined;

    e.stopPropagation();
    showColumnMenu(menuButtonRef.current);
  };

  const renderMenuButton = () => {
    // early return if enableMenu is disabled in grid options
    if (!enableMenu) return null;

    return (
      <span
        ref={menuButtonRef}
        onClick={onMenuClicked}
        onTouchEnd={onMenuClicked}
        data-ref="eMenu"
        className="ag-header-icon ag-header-cell-menu-button ag-header-menu-icon ag-header-menu-always-show"
        aria-hidden="true"
      >
        <span className="ag-icon ag-icon-menu-alt" />
      </span>
    );
  };

  const onFilterClicked = (e) => {
    if (!enableFilterButton) return undefined;

    e.stopPropagation();
    showFilter(filterButtonRef.current);
  };

  const onFilterChanged = () => {
    const activeFilter = column.isFilterActive()
    setIsFilterActive(activeFilter);
  };

  const renderFilterButton = () => {
    if (!enableFilterButton) return null;

    return (
      <span
        ref={filterButtonRef}
        onClick={onFilterClicked}
        onTouchEnd={onFilterClicked}
        data-ref="eFilterButton"
        className={`
          ag-header-icon
          ag-header-cell-filter-button
          ${isFilterActive ? 'ag-filter-active' : ''}
        `}
        aria-hidden="true">
          <span className="ag-icon ag-icon-filter" />
      </span>
    );
  };

  const onSortChanged = () => {
    const sort = column.getSort();
    setAscSort(sort === 'asc' ? '' : 'ag-hidden');
    setDescSort(sort === 'desc' ? '' : 'ag-hidden');
    setNoSort(!sort ? '' : 'ag-hidden');
  };

  const onSortRequested = (event) => {
    if (!enableSorting) return undefined;

    const currentSort = column.getSort();

    switch (currentSort) {
      case 'asc':
        setSort('desc', event.shiftKey);
        break;
      case 'desc':
        setSort('', event.shiftKey);
        break;
      default:
        setSort('asc', event.shiftKey);
        break;
    }
  };

  const renderSortIconContainer = () => {
    // early return if enableSorting is disabled in grid options
    if (!enableSorting) return null;

    return (
      <span className="ag-sort-indicator-container">
        <span data-ref="eSortOrder" className="ag-sort-indicator-icon ag-sort-order ag-hidden" aria-hidden="true" />
        <span data-ref="eSortAsc" className={`ag-sort-indicator-icon ag-sort-ascending-icon ${ascSort}`} aria-hidden="true">
          <span className="ag-icon ag-icon-asc" />
        </span>
        <span data-ref="eSortDesc" className={`ag-sort-indicator-icon ag-sort-descending-icon ${descSort}`} aria-hidden="true">
          <span className="ag-icon ag-icon-desc" />
        </span>
        <span data-ref="eSortMixed" className="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden" aria-hidden="true" />
        <span data-ref="eSortNone" className={`ag-sort-indicator-icon ag-sort-none-icon ag-hidden ${noSort}`} aria-hidden="true">
          <span className="ag-icon ag-icon-none" />
        </span>
      </span>
    );
  };

  useEffect(() => {
    onSortChanged();
    onFilterChanged();

    // event listener to track changes in column sorting
    column.addEventListener('sortChanged', onSortChanged);

    // event listener to track changes in column filter
    column.addEventListener('filterChanged', onFilterChanged);

    // cleanup event listener to avoid memory leaks
    return () => {
      column.removeEventListener('sortChanged', onSortChanged);
      column.removeEventListener('filterChanged', onFilterChanged);
    };
  }, []);

  /*
   * Note: Elements need to be positioned in reverse as Ag-Grid styling applies row-reverse
   * on ag-cell-label-container.
  */
  return (
    <div
      className="ag-cell-label-container ag-header-cell-sorted-asc op2mise-auto-group-header"
      role="presentation"
    >
      {renderMenuButton()}
      {renderFilterButton()}
      <div
        data-ref="eLabel"
        className="ag-header-cell-label"
        role="presentation"
        onClick={(event) => onSortRequested(event)}
      >
        <span data-ref="eText" className="ag-header-cell-text">{displayName}</span>
        {renderSortIconContainer()}
      </div>
      <div className="ag-header-expand-cell-border" />
      <div className="ag-grid-header-expand-container" onClick={handleExpandAll}>
        <img className="ag-grid-header-expand-icon" src={expandAll} alt="expandAll" />
      </div>
    </div>
  );
};
